import { Datagrid, DateField, EmailField, List, Show, SimpleShowLayout, TextField, TextInput } from 'react-admin';
import { MaskingTextField } from '../../component/MaskingTextField';
import { maskingEmail, maskingName, maskingPhone } from '../../utils/masking';

const dealerFilters = [<TextInput label="ra.dealers.search" source="q" alwaysOn />];

export const DealerList = () => {
  return (
    <List sort={{ field: 'id', order: 'DESC' }} filters={dealerFilters}>
      <Datagrid rowClick="show">
        <TextField source="id" />
        <TextField source="name" label="ra.dealers.shopName" />
        <TextField source="dealerName" label="ra.dealers.dealerName" />
        <TextField source="phone" label="ra.dealers.phone" />
        <EmailField source="email" label="ra.dealers.email" />
        <DateField source="createDate" label="ra.dealers.createDate" />
        <DateField source="grantedDate" label="ra.dealers.approveDate" />
      </Datagrid>
    </List>
  );
};

export const DealerShow = () => (
  <Show emptyWhileLoading>
    <SimpleShowLayout>
      <TextField label="ID" source="id" />
      <TextField source="name" label="ra.dealers.shopName" />
      <TextField source="dealerName" label="ra.dealers.dealerName" />
      <TextField source="phone" label="ra.dealers.phone" />
      <EmailField source="email" label="ra.dealers.email" />
      <TextField source="country" label="ra.dealers.country" />
      <TextField source="postcode" label="ra.dealers.zipCode" />
      <TextField source="address" label="ra.dealers.address" />
      <TextField source="addressDetail" label="ra.dealers.addressDetail" />
      <TextField source="businessLicenseNumber" label="ra.dealers.businessLicenseNumber" />
      <DateField source="createDate" label="ra.dealers.createDate" />
      <DateField source="grantedDate" label="ra.dealers.approveDate" />
    </SimpleShowLayout>
  </Show>
);
