import React from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { Button, CardContent, CircularProgress } from '@mui/material';
import { Form, required, useTranslate, useLogin, useSafeSetState } from 'ra-core';
import { TextInput, useNotify } from 'react-admin';
import { FieldValues } from 'react-hook-form';
import { useHandleError } from '../../hooks/useHandleError';
import { AdminAuthResult } from '../../api/auth';
import { resetPasswordDealer } from '../../api/dealers';
import { AuthType } from '../../common/defines';

export const DealerPasswordResetForm = (props: DealerPasswordResetFormProps) => {
  console.log('DealerPasswordResetForm', props);
  const { className, authResult } = props;
  const [loading, setLoading] = useSafeSetState(false);
  const login = useLogin();
  const translate = useTranslate();
  const handleError = useHandleError('ra.auth.signInError');
  const notify = useNotify();

  const submit = async (values: FieldValues) => {
    console.log(values);
    if (values.password !== values.passwordConfirm) {
      return notify('ra.auth.notMatchPasswordConfirm', { type: 'warning' });
    }
    setLoading(true);
    try {
      await resetPasswordDealer({
        id: authResult.payload.id,
        accessToken: authResult.accessToken,
        password: values.password,
      });
      await login(
        {
          username: authResult.payload.email,
          password: values.password,
          mode: AuthType.Dealer,
        },
        '/'
      );
    } catch (e) {
      handleError(e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <StyledForm onSubmit={submit} mode="onChange" noValidate className={className}>
      <CardContent>
        <span>{translate('ra.auth.dealer.needToResetPasswordHelp1')}</span>
        <br />
        <span>{translate('ra.auth.dealer.needToResetPasswordHelp2')}</span>
      </CardContent>
      <CardContent className={LoginFormClasses.content}>
        <TextInput
          source="password"
          label={translate('ra.auth.dealer.newPassword')}
          type="password"
          validate={required('ra.auth.inputPassword')}
          fullWidth
        />
        <TextInput
          source="passwordConfirm"
          label={translate('ra.auth.dealer.newPasswordConfirm')}
          type="password"
          validate={required('ra.auth.inputPasswordConfirm')}
          fullWidth
        />

        <Button
          variant="contained"
          type="submit"
          color="primary"
          disabled={loading}
          fullWidth
          className={LoginFormClasses.button}
        >
          {loading ? (
            <CircularProgress className={LoginFormClasses.icon} size={19} thickness={3} />
          ) : (
            translate('ra.auth.dealer.resetPassword')
          )}
        </Button>
      </CardContent>
    </StyledForm>
  );
};

const PREFIX = 'RaLoginForm';

const LoginFormClasses = {
  content: `${PREFIX}-content`,
  button: `${PREFIX}-button`,
  join: `${PREFIX}-button`,
  icon: `${PREFIX}-icon`,
};

const StyledForm = styled(Form, {
  name: PREFIX,
  overridesResolver: (props, styles) => styles.root,
})(({ theme }) => ({
  [`& .${LoginFormClasses.content}`]: {
    width: 350,
  },
  [`& .${LoginFormClasses.button}`]: {
    marginTop: theme.spacing(2),
  },
  [`& .${LoginFormClasses.icon}`]: {
    margin: theme.spacing(0.3),
  },
}));

export interface DealerPasswordResetFormProps {
  redirectTo?: string;
  className?: string;
  authResult: AdminAuthResult;
}
DealerPasswordResetForm.propTypes = {
  redirectTo: PropTypes.string,
};
