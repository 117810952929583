import React from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { Button, CardContent, CircularProgress } from '@mui/material';
import { Form, required, useTranslate, useSafeSetState } from 'ra-core';
import { SelectInput, TextInput, useNotify } from 'react-admin';
import { joinDealer, sendEmailKey } from '../../api/dealers';
import { checkEmail } from '../../utils/checkEmail';
import { CreateDealerDto, CreateDealerDtoDefaultValue, CreateDealerDtoKey } from '../../typing/common';
import { LoginPageMode } from '.';
import { p } from '../../utils/korean';
import { Countries } from '../../common/countries';
// import BackIcon from '@mui/icons-material/ArrowBack';

export interface DealerJoinFormProps {
  onBack: () => void;
}

export const DealerJoinForm = (props: DealerJoinFormProps) => {
  const { onBack } = props;
  const [loading, setLoading] = useSafeSetState(false);
  const [values, setValues] = React.useState<CreateDealerDto>({
    ...CreateDealerDtoDefaultValue,
    country: Countries.find(v => v.selected)?.id ?? '',
  });
  const [isSendingVerifyEmail, setIsSendingVerifyEmail] = React.useState(false);
  const [isSendVerify, setIsSendVerify] = useSafeSetState<boolean>(false);
  const translate = useTranslate();
  const notify = useNotify();

  const handleChange = (key: CreateDealerDtoKey, value: string) => {
    setValues(v => ({ ...v, [key]: value }));
  };

  const warning = (message: string) => notify(translate(message), { type: 'warning' });

  const handleSendEmailVerifyKey = async () => {
    const { email } = values;
    if (!checkEmail(email)) return warning(translate('ra.auth.dealer.checkEmail'));
    try {
      setIsSendingVerifyEmail(true);
      const result = await sendEmailKey(email ?? '');
      if (!result) {
        warning(translate('ra.auth.dealer.failSendEmail'));
        throw new Error('fail to send email');
      }
      setIsSendVerify(true);
      notify(translate('ra.auth.dealer.checkEmailVerifyKey'), {
        type: 'info',
      });
    } catch (e) {
      if ((e as any)?.response?.status === 409) {
        warning(translate('ra.auth.dealer.failExistEmail'));
      } else {
        warning(translate('ra.auth.dealer.failSendEmail'));
      }
      console.warn(e);
    } finally {
      setIsSendingVerifyEmail(false);
      setLoading(false);
    }
  };

  const submit = async () => {
    const { emailVerifyKey, password, passwordConfirm } = values;
    if (emailVerifyKey?.length !== 6) return warning(translate('ra.auth.dealer.inputEmailVerifyKey'));
    if (password?.length < 7) return warning(translate('ra.auth.dealer.inputPasswordMoreEight'));
    if (password !== passwordConfirm) {
      return warning(translate('ra.auth.dealer.notMatchPasswordConfirm'));
    }

    setLoading(true);
    try {
      const result = await joinDealer(values);
      if (result) {
        notify(translate('ra.auth.dealer.joinSuccess'), { multiLine: true });
      }
      onBack();
    } catch (e) {
      console.warn(e);
    } finally {
      setLoading(false);
    }
  };

  const renderInput = (key: keyof CreateDealerDto, validate: string, options: any = {}) => {
    return (
      <TextInput
        source={key}
        label={translate(`ra.auth.dealer.${key}`)}
        validate={validate ? required(validate) : undefined}
        disabled={loading}
        fullWidth
        value={values[key]}
        onChange={e => handleChange(key, e.target.value)}
        autoComplete="off"
        {...options}
      />
    );
  };

  const renderLoading = () => {
    return <CircularProgress className={LoginFormClasses.icon} size={19} thickness={3} />;
  };

  return (
    <StyledForm onSubmit={submit} mode="onChange" noValidate>
      <CardContent className={LoginFormClasses.content}>
        <TextInput
          autoFocus
          source="email"
          label={translate('ra.auth.dealer.username')}
          validate={required(translate('ra.auth.dealer.inputEmail'))}
          disabled={isSendVerify || isSendingVerifyEmail}
          fullWidth
          onChange={e => handleChange('email', e.target.value)}
        />
        <Button
          variant="contained"
          type="button"
          color="info"
          disabled={loading || isSendVerify || isSendingVerifyEmail}
          fullWidth
          className={LoginFormClasses.sendEmailVerify}
          onClick={handleSendEmailVerifyKey}
        >
          {loading && !isSendVerify ? renderLoading() : translate('ra.auth.dealer.sendEmailKey')}
        </Button>
        {isSendVerify && (
          <>
            {renderInput('emailVerifyKey', 'ra.auth.dealer.inputEmailVerifyKey')}
            {renderInput('password', 'ra.auth.dealer.inputPassword', { type: 'password' })}
            {renderInput('passwordConfirm', 'ra.auth.dealer.inputPasswordConfirm', { type: 'password' })}
            {renderInput('name', 'ra.auth.dealer.inputName')}
            {renderInput('dealerName', 'ra.auth.dealer.inputDealerName')}
            {renderInput('phone', 'ra.auth.dealer.inputPhone')}
            {/* {renderInput('country')} */}
            <SelectInput
              source="country"
              label={translate(`ra.auth.dealer.country`)}
              validate={required('ra.auth.dealer.inputCountry')}
              disabled={loading}
              fullWidth
              defaultValue={Countries.find(v => v.selected)?.id}
              choices={Countries}
              optionText={'id'}
              value={Countries.find(v => v.selected)?.id}
              onChange={e => {
                handleChange('country', e.target.value);
                console.log(e.target.value);
              }}
            />
            {renderInput('postcode', 'ra.auth.dealer.inputZipNumber')}
            {renderInput('address', 'ra.auth.dealer.inputAddress')}
            {renderInput('addressDetail', 'ra.auth.dealer.inputAddressDetail')}
            {renderInput('businessLicenseNumber', 'ra.auth.dealer.inputCompanyRegistrationNumber')}

            <Button
              variant="contained"
              type="submit"
              color="primary"
              disabled={loading}
              fullWidth
              className={LoginFormClasses.button}
            >
              {loading ? renderLoading() : translate('ra.auth.dealer.join')}
            </Button>
          </>
        )}
        <Button
          variant="contained"
          type="button"
          color="info"
          disabled={loading}
          fullWidth
          className={LoginFormClasses.join}
          onClick={onBack}
        >
          {loading ? renderLoading() : translate('ra.auth.dealer.joinCancel')}
        </Button>
      </CardContent>
    </StyledForm>
  );
};

const PREFIX = 'RaLoginForm';

const LoginFormClasses = {
  content: `${PREFIX}-content`,
  button: `${PREFIX}-button`,
  join: `${PREFIX}-button`,
  icon: `${PREFIX}-icon`,
  sendEmailVerify: `${PREFIX}-sendEmailVerify`,
};

const StyledForm = styled(Form, {
  name: PREFIX,
  overridesResolver: (props, styles) => styles.root,
})(({ theme }) => ({
  '& .container': {
    overflow: 'scroll',
  },
  [`& .${LoginFormClasses.content}`]: {
    width: 350,
    maxHeight: 500,
    overflow: 'scroll',
  },
  [`& .${LoginFormClasses.join}`]: {
    marginTop: theme.spacing(1),
    marignBottom: theme.spacing(1),
  },
  [`& .${LoginFormClasses.sendEmailVerify}`]: {
    marignBottom: `important ${theme.spacing(5)}`,
  },
  [`& .${LoginFormClasses.button}`]: {
    marginTop: theme.spacing(2),
  },
  [`& .${LoginFormClasses.icon}`]: {
    margin: theme.spacing(0.3),
  },
}));

export interface LoginFormProps extends LoginPageMode {
  redirectTo?: string;
  className?: string;
}

interface FormData {
  username: string;
  password: string;
}
DealerJoinForm.propTypes = {
  redirectTo: PropTypes.string,
};
