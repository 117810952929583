// in src/MyMenu.js
import * as React from 'react';
import {
  DashboardMenuItem,
  Menu as RAMenu,
  MenuItemLink,
  MenuProps,
  useAuthState,
  useGetIdentity,
  usePermissions,
  useResourceDefinitions,
} from 'react-admin';
import DefaultIcon from '@mui/icons-material/ViewList';

import { createElement } from 'react';

export const Menu = (props: MenuProps) => {
  const resources = useResourceDefinitions();
  const auth = useAuthState();
  const permissions = usePermissions();
  const { identity, isLoading: identityLoading } = useGetIdentity();

  // console.log('identity', identity, permissions);
  return (
    <RAMenu {...props}>
      <DashboardMenuItem />
      {Object.keys(resources).map(name => {
        const { icon, options } = resources[name];
        // console.log('name', options);
        if (options.permissions !== permissions.permissions) return;
        return (
          <MenuItemLink
            key={name}
            to={`/${name}`}
            primaryText={(options && options.label) || name}
            leftIcon={icon ? createElement(icon) : <DefaultIcon />}
          />
        );
      })}
    </RAMenu>
  );
};
