import { GetListParams, GetListResult } from 'ra-core';
import api from '.';
import { Bov, CreateBovDto, UpdateBovDto } from '../typing/common';
import { ListResponse } from '../typing/common';
import {
  CreateParams,
  CreateResult,
  DeleteManyParams,
  DeleteManyResult,
  DeleteParams,
  DeleteResult,
  GetOneResult,
  UpdateParams,
} from 'react-admin';

export const bovs = async ({
  pagination: { page, perPage },
  sort: { field, order },
}: GetListParams): Promise<GetListResult<Bov>> => {
  const {
    data: { items, meta },
  }: ListResponse<Bov> = await api.get('bovs', {
    params: {
      page,
      size: perPage,
      sort: field,
      order,
    },
  });

  return {
    data: items,
    total: meta.totalItems,
    pageInfo: {
      hasNextPage: meta.totalPages > meta.currentPage,
      hasPreviousPage: meta.currentPage > 1,
    },
  };
};

export const bov = async (id: number): Promise<GetOneResult<Bov>> => {
  const result = await api.get(`bovs/${id}`);
  return result as GetOneResult<Bov>;
};

export const createBov = async ({ data }: CreateParams<CreateBovDto>): Promise<CreateResult<Bov>> => {
  console.log('createBov', data);
  const result = await api.post('bovs', data);
  return result as CreateResult<Bov>;
};

export const updateBov = async ({ data }: UpdateParams<UpdateBovDto>): Promise<CreateResult<Bov>> => {
  console.log('updateBov', data);
  const result = await api.patch(`bovs/${data.id}`, data);
  return result as CreateResult<Bov>;
};

export const deleteBov = async ({ id }: DeleteParams<Bov>): Promise<DeleteResult> => {
  // const { payload: self } = await getCurrentAuth();
  // console.log(self.id, id);

  const result = await api.delete(`bovs/${id}`);
  return { data: true } as DeleteResult;
};

export const deleteBovs = async ({ ids }: DeleteManyParams<Bov>): Promise<DeleteManyResult> => {
  // const { payload: self } = await getCurrentAuth();
  // console.log(self.id, ids);

  const result = await api.delete(`bovs/${ids.join(',')}`);
  return {} as DeleteManyResult;
};
