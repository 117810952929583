import * as React from 'react';
import { memo, FC, ElementType } from 'react';
import get from 'lodash/get';
import Typography, { TypographyProps } from '@mui/material/Typography';
import { useRecordContext } from 'ra-core';
import { InjectedFieldProps, PublicFieldProps } from 'react-admin';

export interface MaskingTextFieldProps
  extends PublicFieldProps,
    InjectedFieldProps,
    Omit<TypographyProps, 'textAlign'> {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  component?: ElementType<any>;
  masking: (value: string) => string;
}

export const MaskingTextField: FC<MaskingTextFieldProps> = memo(props => {
  const { className, source, emptyText, masking, ...rest } = props;
  const record = useRecordContext(props);
  const value = get(record, source ?? '');

  return (
    <Typography component="span" variant="body2" className={className} {...rest}>
      {value != null && typeof value !== 'string' ? JSON.stringify(value) : masking(value) || emptyText}
    </Typography>
  );
});

// what? TypeScript loses the displayName if we don't set it explicitly
MaskingTextField.displayName = 'MaskingTextField';

MaskingTextField.propTypes = {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  ...Typography.propTypes,
};

MaskingTextField.defaultProps = {
  masking: (v: string) => v,
};
