import { AxiosError } from 'axios';
import { AuthProvider } from 'react-admin';
import api from '../api';
import { AdminAuthResult, AdminUserIdentifier, authAdmin, authDealer } from '../api/auth';

const LOCAL_KEY = 'adminInfo';

export const getCurrentAuth = (): AdminAuthResult | null => {
  const loginInfoRaw = localStorage.getItem(LOCAL_KEY);
  if (!loginInfoRaw) return null;
  try {
    return JSON.parse(loginInfoRaw);
  } catch (e) {
    console.log(e);
  }
  return null;
};

export default {
  // called when the user attempts to log in
  login: async ({ username, password, mode }: { username: string; password: string; mode: 'admin' | 'dealer' }) => {
    console.log({ mode, username, password });
    try {
      const endPoint = mode === 'admin' ? authAdmin : authDealer;
      const result = await endPoint({ email: username, password });
      if (result.payload.isTempPassword) return Promise.reject(result);
      api.defaults.headers.common.Authorization = `Bearer ${result.accessToken}`;
      localStorage.setItem(LOCAL_KEY, JSON.stringify(result));
      return Promise.resolve(result);
    } catch (e) {
      if ((e as any)?.response?.data?.message === 'Not granted') {
        return Promise.reject(new Error('ra.auth.notGranted'));
      }
      if ((e as any)?.response?.data?.message === 'Not Found') {
        return Promise.reject(new Error('ra.auth.notFound'));
      }
      if ((e as any)?.response?.data?.message === 'Not Match Password') {
        return Promise.reject(new Error('ra.auth.notMatchPassword'));
      }
      console.log('authProvider login error', (e as any)?.response?.data?.message);
      return Promise.reject(e);
    }
  },
  // called when the user clicks on the logout button
  logout: () => {
    localStorage.removeItem(LOCAL_KEY);
    delete api.defaults.headers.common.Authorization;
    return Promise.resolve();
  },
  // called when the API returns an error
  checkError: (error: AxiosError) => {
    console.log('checkError', error);
    const { status } = error?.response || {};
    if (status === 401 || status === 403) {
      localStorage.removeItem(LOCAL_KEY);
      delete api.defaults.headers.common.Authorization;
      return Promise.reject();
    }
    return Promise.resolve();
  },
  getIdentity: async (): Promise<AdminUserIdentifier> => {
    const result = getCurrentAuth();
    if (!result) return Promise.reject();
    const { accessToken } = result;
    api.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    return {
      fullName: result.payload.name,
      authType: result.authType,
      ...result.payload,
    };
  },
  // called when the user navigates to a new location, to check for authentication
  checkAuth: async (): Promise<void> => {
    const result = getCurrentAuth();
    if (!result) return Promise.reject('no have identity');
    if (result) {
      api.defaults.headers.common.Authorization = `Bearer ${result.accessToken}`;
      return Promise.resolve();
    } else return Promise.reject();
  },
  // called when the user navigates to a new location, to check for permissions / roles
  getPermissions: () => {
    const result = getCurrentAuth();
    return result ? Promise.resolve(result.authType) : Promise.resolve('guest');
  },
} as AuthProvider;
