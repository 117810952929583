import axios from 'axios';

interface ISTATUS_CODE {
  [key: string]: string;
}
export const STATUS_CODE: ISTATUS_CODE = {
  // 관리자 로그인
  ADMIN_LOGIN_SUCCESS: 'ra.statusCodeKey.adminLoginSuccess',
  ADMIN_LOGIN_FAILED: 'ra.statusCodeKey.adminLoginFailed',
  ADMIN_LOGIN_FAILED_NOT_FOUND_USER: 'ra.statusCodeKey.adminLoginFailedNotFoundUser',
  // 딜러 로그인
  DEALER_LOGIN_SUCCESS: 'ra.statusCodeKey.dealerLoginSuccess',
  DEALER_LOGIN_FAILED: 'ra.statusCodeKey.dealerLoginFailed',
  DEALER_LOGIN_FAILED_NOT_FOUND_USER: 'ra.statusCodeKey.dealerLoginFailedNotFoundUser',

  // 로그인
  LOGIN_SUCCESS: 'ra.statusCodeKey.loginSuccess',
  LOGIN_FAILED: 'ra.statusCodeKey.loginFailed',
  LOGIN_FAILED_NOT_FOUND_USER: 'ra.statusCodeKey.loginFailedNotFoundUser',
  // SNS 로그인
  SNS_LOGIN_SUCCESS: 'ra.statusCodeKey.snsLoginSuccess',
  SNS_LOGIN_FAILED: 'ra.statusCodeKey.snsLoginFailed',
  SNS_LOGIN_FAILED_NOT_FOUND_USER: 'ra.statusCodeKey.snsLoginFailedNotFoundUser',

  // 로그아웃
  LOGOUT_SUCCESS: 'ra.statusCodeKey.logoutSuccess',
  LOGOUT_FAILED: 'ra.statusCodeKey.logoutFailed',
  // CHECK AUTH
  CHECK_AUTH_SUCCESS: 'ra.statusCodeKey.checkAuthSuccess',
  CHECK_AUTH_FAILED: 'ra.statusCodeKey.checkAuthFailed',
  CHECK_AUTH_FAILED_NOT_FOUND_USER: 'ra.statusCodeKey.checkAuthFailedNotFoundUser',
  // Token
  TOKEN_REFRESH_SUCCESS: 'ra.statusCodeKey.tokenRefreshSuccess',
  TOKEN_REFRESH_FAILED: 'ra.statusCodeKey.tokenRefreshFailed',
  // 사용자 목록 조회
  GET_USER_LIST_SUCCESS: 'ra.statusCodeKey.getUserListSuccess',
  GET_USER_LIST_FAILED: 'ra.statusCodeKey.getUserListFailed',
  // 사용자 상세 조회
  GET_USER_SUCCESS: 'ra.statusCodeKey.getUserSuccess',
  GET_USER_FAILED: 'ra.statusCodeKey.getUserFailed',
  // 사용자 삭제
  DELETE_USER_SUCCESS: 'ra.statusCodeKey.deleteUserSuccess',
  DELETE_USER_FAILED: 'ra.statusCodeKey.deleteUserFailed',

  // 이메일 인증 코드 전송
  SEND_EMAIL_AUTH_SUCCESS: 'ra.statusCodeKey.sendEmailAuthSuccess',
  SEND_EMAIL_AUTH_FAILED: 'ra.statusCodeKey.sendEmailAuthFailed',
  // 이메일 인증 코드 체크
  CHECK_EMAIL_AUTH_SUCCESS: 'ra.statusCodeKey.checkEmailAuthSuccess',
  CHECK_EMAIL_AUTH_FAILED: 'ra.statusCodeKey.checkEmailAuthFailed',
  // 딜러 코드 확인
  CHECK_DEALER_CODE_SUCCESS: 'ra.statusCodeKey.checkDealerCodeSuccess',
  CHECK_DEALER_CODE_FAILED: 'ra.statusCodeKey.checkDealerCodeFailed',
  // 임시 비밀번호 전송( 비밀번호 변경 )
  SEND_TEMP_PASSWORD_SUCCESS: 'ra.statusCodeKey.sendTempPasswordSuccess',
  SEND_TEMP_PASSWORD_FAILED: 'ra.statusCodeKey.sendTempPasswordFailed',
  // 회원가입( 사용자 등록 )
  SIGNUP_SUCCESS: 'ra.statusCodeKey.signupSuccess',
  SIGNUP_FAILED: 'ra.statusCodeKey.signupFailed',
  SIGNUP_FAILED_DUPLICATE_EMAIL: 'ra.statusCodeKey.signupFailedDuplicateEmail',
  SIGNUP_FAILED_DUPLICATE_NICKNAME: 'ra.statusCodeKey.signupFailedDuplicateNickname',
  // SNS 회원가입( 사용자 등록 )
  SNS_SIGNUP_SUCCESS: 'ra.statusCodeKey.snsSignupSuccess',
  SNS_SIGNUP_FAILED: 'ra.statusCodeKey.snsSignupFailed',
  SNS_SIGNUP_FAILED_DUPLICATE_UID: 'ra.statusCodeKey.snsSignupFailedDuplicateUid',
  SNS_SIGNUP_FAILED_DUPLICATE_EMAIL: 'ra.statusCodeKey.snsSignupFailedDuplicateEmail',
  // 회원정보 수정
  MODIFY_USER_INFO_SUCCESS: 'ra.statusCodeKey.modifyUserInfoSuccess',
  MODIFY_USER_INFO_FAILED: 'ra.statusCodeKey.modifyUserInfoFailed',
  // 회원 설정 정보 수정
  MODIFY_USER_SETTING_INFO_SUCCESS: 'ra.statusCodeKey.modifyUserSettingInfoSuccess',
  MODIFY_USER_SETTING_INFO_FAILED: 'ra.statusCodeKey.modifyUserSettingInfoFailed',
  // 기기 등록
  REGISTER_DEVICE_SUCCESS: 'ra.statusCodeKey.registerDeviceSuccess',
  REGISTER_DEVICE_FAILED: 'ra.statusCodeKey.registerDeviceFailed',
  // 회원탈퇴
  WITHDRAWAL_SUCCESS: 'ra.statusCodeKey.withdrawalSuccess',
  WITHDRAWAL_FAILED: 'ra.statusCodeKey.withdrawalFailed',
  // Apple 로그인 사용자 이름 저장
  SAVE_APPLE_USER_NAME_SUCCESS: 'ra.statusCodeKey.saveAppleUserNameSuccess',
  SAVE_APPLE_USER_NAME_FAILED: 'ra.statusCodeKey.saveAppleUserNameFailed',
  // Apple 로그인 사용자 이름 조회
  GET_APPLE_USER_NAME_SUCCESS: 'ra.statusCodeKey.getAppleUserNameSuccess',
  GET_APPLE_USER_NAME_FAILED: 'ra.statusCodeKey.getAppleUserNameFailed',
  // 이미지 업로드
  UPLOAD_IMAGE_SUCCESS: 'ra.statusCodeKey.uploadImageSuccess',
  UPLOAD_IMAGE_FAILED: 'ra.statusCodeKey.uploadImageFailed',
  // 파일 업로드
  UPLOAD_FILE_SUCCESS: 'ra.statusCodeKey.uploadFileSuccess',
  UPLOAD_FILE_FAILED: 'ra.statusCodeKey.uploadFileFailed',
  // 파일 조회
  GET_FILE_SUCCESS: 'ra.statusCodeKey.getFileSuccess',
  GET_FILE_FAILED: 'ra.statusCodeKey.getFileFailed',
  // 파일 삭제
  DELETE_FILE_SUCCESS: 'ra.statusCodeKey.deleteFileSuccess',
  DELETE_FILE_FAILED: 'ra.statusCodeKey.deleteFileFailed',
  // 메뉴얼 전체 목록 조회
  GET_MANUAL_LIST_SUCCESS: 'ra.statusCodeKey.getManualListSuccess',
  GET_MANUAL_LIST_FAILED: 'ra.statusCodeKey.getManualListFailed',
  // 메뉴얼 조회
  GET_MANUAL_SUCCESS: 'ra.statusCodeKey.getManualSuccess',
  GET_MANUAL_FAILED: 'ra.statusCodeKey.getManualFailed',
  // BLE 기기 등록
  REGISTER_BLE_DEVICE_SUCCESS: 'ra.statusCodeKey.registerBleDeviceSuccess',
  REGISTER_BLE_DEVICE_FAILED: 'ra.statusCodeKey.registerBleDeviceFailed',
  // BLE 기기 조회
  GET_BLE_DEVICE_SUCCESS: 'ra.statusCodeKey.getBleDeviceSuccess',
  GET_BLE_DEVICE_FAILED: 'ra.statusCodeKey.getBleDeviceFailed',
  // 관리자 목록 조회
  GET_ADMIN_LIST_SUCCESS: 'ra.statusCodeKey.getAdminListSuccess',
  GET_ADMIN_LIST_FAILED: 'ra.statusCodeKey.getAdminListFailed',
  // 관리자 등록
  SIGNUP_ADMIN_SUCCESS: 'ra.statusCodeKey.signupAdminSuccess',
  SIGNUP_ADMIN_FAILED: 'ra.statusCodeKey.signupAdminFailed',
  // 관리자 조회
  GET_ADMIN_SUCCESS: 'ra.statusCodeKey.getAdminSuccess',
  GET_ADMIN_FAILED: 'ra.statusCodeKey.getAdminFailed',
  // 관리자 수정
  MODIFY_ADMIN_SUCCESS: 'ra.statusCodeKey.modifyAdminSuccess',
  MODIFY_ADMIN_FAILED: 'ra.statusCodeKey.modifyAdminFailed',
  // 관리자 삭제
  DELETE_ADMIN_SUCCESS: 'ra.statusCodeKey.deleteAdminSuccess',
  DELETE_ADMIN_FAILED: 'ra.statusCodeKey.deleteAdminFailed',
  // 가입 인증 메일 전송
  SEND_AUTH_EMAIL_SUCCESS: 'ra.statusCodeKey.sendAuthEmailSuccess',
  SEND_AUTH_EMAIL_FAILED: 'ra.statusCodeKey.sendAuthEmailFailed',
  // 딜러 가입
  SIGNUP_DEALER_SUCCESS: 'ra.statusCodeKey.signupDealerSuccess',
  SIGNUP_DEALER_FAILED: 'ra.statusCodeKey.signupDealerFailed',
  SIGNUP_DEALER_FAILED_DUPLICATE_EMAIL: 'ra.statusCodeKey.signupDealerFailedDuplicateEmail',
  SIGNUP_DEALER_FAILED_DUPLICATE_NICKNAME: 'ra.statusCodeKey.signupDealerFailedDuplicateNickname',
  // 딜러 목록 조회
  GET_DEALER_LIST_SUCCESS: 'ra.statusCodeKey.getDealerListSuccess',
  GET_DEALER_LIST_FAILED: 'ra.statusCodeKey.getDealerListFailed',
  // 딜러 상세 조회
  GET_DEALER_SUCCESS: 'ra.statusCodeKey.getDealerSuccess',
  GET_DEALER_FAILED: 'ra.statusCodeKey.getDealerFailed',
  // 딜러 수정
  MODIFY_DEALER_SUCCESS: 'ra.statusCodeKey.modifyDealerSuccess',
  MODIFY_DEALER_FAILED: 'ra.statusCodeKey.modifyDealerFailed',
  // 딜러 삭제
  DELETE_DEALER_SUCCESS: 'ra.statusCodeKey.deleteDealerSuccess',
  DELETE_DEALER_FAILED: 'ra.statusCodeKey.deleteDealerFailed',
  // 딜러 가입 승인
  APPROVE_DEALER_SUCCESS: 'ra.statusCodeKey.approveDealerSuccess',
  APPROVE_DEALER_FAILED: 'ra.statusCodeKey.approveDealerFailed',
  // 딜러 패스워드 초기화
  RESET_DEALER_PASSWORD_SUCCESS: 'ra.statusCodeKey.resetDealerPasswordSuccess',
  RESET_DEALER_PASSWORD_FAILED: 'ra.statusCodeKey.resetDealerPasswordFailed',
  // 메뉴얼 등록
  REGISTER_MANUAL_SUCCESS: 'ra.statusCodeKey.registerManualSuccess',
  REGISTER_MANUAL_FAILED: 'ra.statusCodeKey.registerManualFailed',
  // 메뉴얼 수정
  MODIFY_MANUAL_SUCCESS: 'ra.statusCodeKey.modifyManualSuccess',
  MODIFY_MANUAL_FAILED: 'ra.statusCodeKey.modifyManualFailed',
  // 메뉴얼 삭제
  DELETE_MANUAL_SUCCESS: 'ra.statusCodeKey.deleteManualSuccess',
  DELETE_MANUAL_FAILED: 'ra.statusCodeKey.deleteManualFailed',
  // Bov 목록 조회
  GET_BOV_LIST_SUCCESS: 'ra.statusCodeKey.getBovListSuccess',
  GET_BOV_LIST_FAILED: 'ra.statusCodeKey.getBovListFailed',
  // Bov 등록
  REGISTER_BOV_SUCCESS: 'ra.statusCodeKey.registerBovSuccess',
  REGISTER_BOV_FAILED: 'ra.statusCodeKey.registerBovFailed',
  // Bov 상세 조회
  GET_BOV_SUCCESS: 'ra.statusCodeKey.getBovSuccess',
  GET_BOV_FAILED: 'ra.statusCodeKey.getBovFailed',
  // Bov 수정
  MODIFY_BOV_SUCCESS: 'ra.statusCodeKey.modifyBovSuccess',
  MODIFY_BOV_FAILED: 'ra.statusCodeKey.modifyBovFailed',
  // Bov 삭제
  DELETE_BOV_SUCCESS: 'ra.statusCodeKey.deleteBovSuccess',
  DELETE_BOV_FAILED: 'ra.statusCodeKey.deleteBovFailed',
  // 푸시 메시지 전송
  SEND_PUSH_MESSAGE_SUCCESS: 'ra.statusCodeKey.sendPushMessageSuccess',
  SEND_PUSH_MESSAGE_FAILED: 'ra.statusCodeKey.sendPushMessageFailed',
  // 푸시 상세 메시지 전송
  SEND_PUSH_DETAIL_MESSAGE_SUCCESS: 'ra.statusCodeKey.sendPushDetailMessageSuccess',
  SEND_PUSH_DETAIL_MESSAGE_FAILED: 'ra.statusCodeKey.sendPushDetailMessageFailed',
  // 푸시 상세 메시지 전송( 다건 )
  SEND_PUSH_DETAIL_MESSAGES_SUCCESS: 'ra.statusCodeKey.sendPushDetailMessagesSuccess',
  SEND_PUSH_DETAIL_MESSAGES_FAILED: 'ra.statusCodeKey.sendPushDetailMessagesFailed',
  // 기간별 가입자 현황 조회
  GET_DASHBOARD_SIGNUP_STATISTICS_SUCCESS: 'ra.statusCodeKey.getDashboardSignupStatisticsSuccess',
  GET_DASHBOARD_SIGNUP_STATISTICS_FAILED: 'ra.statusCodeKey.getDashboardSignupStatisticsFailed',
  // 전체 가입수 조회
  GET_DASHBOARD_SIGNUP_TOTAL_SUCCESS: 'ra.statusCodeKey.getDashboardSignupTotalSuccess',
  GET_DASHBOARD_SIGNUP_TOTAL_FAILED: 'ra.statusCodeKey.getDashboardSignupTotalFailed',
};

// 절대 배포 되면 안됨
// export const apiHost = 'http://127.0.0.1:3020';
export const apiHost = 'https://xhaust-admin.com:3020';
export const baseURL = `${apiHost}/api`;
const api = axios.create({
  baseURL,
  headers: {
    'Content-Type': 'application/json',
    // 'Content-Type': 'application/x-www-form-urlencoded',
    Accept: 'application/json',
    responseType: 'application/json',
  },
});

export default api;
