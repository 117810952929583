import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import { LineChart, Line, XAxis, YAxis, Label, ResponsiveContainer } from 'recharts';
import Title from './Title';
import { ChartData, usersChart } from '../../api/dashboard';

export default function Chart() {
  const theme = useTheme();
  const [data, setData] = React.useState<ChartData[]>([]);

  const loadChartData = async () => {
    const close = new Date();
    const year = `${close.getFullYear()}`;
    const month = `${close.getMonth() + 1}`.padStart(2, '0');
    const date = `${close.getDate()}`.padStart(2, '0');
    const datas = await usersChart('', `${year}-${month}-${date}`);
    console.log(datas);
    setData(datas);
  };

  React.useEffect(() => {
    loadChartData();
  }, []);

  return (
    <React.Fragment>
      <Title>Today</Title>
      <ResponsiveContainer>
        <LineChart
          data={data}
          margin={{
            top: 16,
            right: 16,
            bottom: 0,
            left: 24,
          }}
        >
          <XAxis dataKey="date" stroke={theme.palette.text.secondary} style={theme.typography.body2} />
          <YAxis stroke={theme.palette.text.secondary} style={theme.typography.body2}>
            <Label
              angle={270}
              position="left"
              style={{
                textAnchor: 'middle',
                fill: theme.palette.text.primary,
                ...theme.typography.body1,
              }}
            >
              User
            </Label>
          </YAxis>
          <Line
            isAnimationActive={false}
            type="monotone"
            dataKey="count"
            stroke={theme.palette.primary.main}
            dot={false}
          />
        </LineChart>
      </ResponsiveContainer>
    </React.Fragment>
  );
}
