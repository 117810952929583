import {
  CreateBase,
  Datagrid,
  DateField,
  EditBase,
  EditButton,
  EmailField,
  List,
  PasswordInput,
  RichTextField,
  Show,
  SimpleForm,
  SimpleShowLayout,
  TextField,
  TextInput,
  useTranslate,
} from 'react-admin';
import { Card } from '@mui/material';
import { RecordTitle } from '../../component/RecordTitle';

export const AdminList = () => {
  const translate = useTranslate();
  return (
    <List sort={{ field: 'id', order: 'DESC' }}>
      <Datagrid rowClick="show">
        <TextField source="id" maxWidth={80} />
        <TextField source="name" label={translate('ra.admin.name')} maxWidth={150} />
        <EmailField source="email" label={translate('ra.admin.email')} />
        <DateField source="createDate" label={translate('ra.admin.createDate')} />
        <EditButton />
      </Datagrid>
    </List>
  );
};

export const AdminShow = () => {
  return (
    <Show emptyWhileLoading>
      <SimpleShowLayout>
        <TextField source="email" />
        <TextField source="name" />
        <RichTextField source="body" />
        <DateField label="Publication date" source="published_at" />
      </SimpleShowLayout>
    </Show>
  );
};

const Editor = () => {
  const translate = useTranslate();
  return (
    <div>
      <RecordTitle title={translate('ra.admin.adminAccount')} />
      <Card>
        <SimpleForm>
          <TextInput source="email" label={translate('ra.admin.email')} />
          <TextInput source="name" label={translate('ra.admin.name')} />
          <PasswordInput source="new_password" label={translate('ra.admin.newPassword')} />
          <PasswordInput source="new_passwordConfirm" label={translate('ra.admin.newPasswordConfirm')} />
        </SimpleForm>
      </Card>
    </div>
  );
};
export const AdminCreate = () => (
  <CreateBase>
    <Editor />
  </CreateBase>
);

export const AdminEdit = () => (
  <EditBase>
    <Editor />
  </EditBase>
);
