import * as React from 'react';
import { Card, CardContent } from '@mui/material';
import {
  CheckboxGroupInput,
  RadioButtonGroupInput,
  SaveButton,
  SelectArrayInput,
  SelectInput,
  SimpleForm,
  TextInput,
  Title,
  Toolbar,
  useI18nProvider,
  useTranslate,
} from 'react-admin';
import SendIcon from '@mui/icons-material/Send';
import { SupportedLanguages, TSupportedLanguage } from '../../common/i18nProvider';
import { Manual, PushNotificationDto } from '../../typing/common';
import { manuals as getManuals } from '../../api/manuals';
import api from '../../api';
import { send } from '../../api/notifications';
const NotificationToolBar = () => {
  // const redirect = useRedirect();
  // const notify = useNotify();
  const translate = useTranslate();
  return (
    <Toolbar>
      <SaveButton label={translate('ra.action.sendNotification')} icon={<SendIcon />} />
    </Toolbar>
  );
};
const Notifications = () => {
  const translate = useTranslate();
  const i18n = useI18nProvider();
  const currentLocale = i18n.getLocale() as keyof TSupportedLanguage;
  const [payload, setPayload] = React.useState<'none' | 'manual' | 'webpage'>('none');
  const [manuals, setManuals] = React.useState<Manual[]>([]);
  const loadManuals = async () => {
    const { data: _manuals } = await getManuals({
      pagination: { page: 1, perPage: 15 },
      sort: { field: 'id', order: 'DESC' },
      filter: 'none',
    });
    setManuals(_manuals);
  };
  React.useEffect(() => {
    loadManuals();
  }, []);

  const handleChangePayload = (...args: any[]) => {
    const [event, value] = args;
    setPayload(value);
    if (value === 'manual') loadManuals();
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleSend = async (formValue: Record<string, any>) => {
    if (!confirm(translate('ra.notification.confirmSend'))) return;
    console.log('haha', formValue as PushNotificationDto);
    send(formValue as PushNotificationDto);
  };

  return (
    <Card>
      <Title title="ra.side.notifications" />
      <CardContent>
        <SimpleForm
          toolbar={<NotificationToolBar />}
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          onSubmit={handleSend}
        >
          {SupportedLanguages.map(locale => {
            return (
              <TextInput
                key={`title[${locale}]`}
                source={`title[${locale}]`}
                label={`${translate('ra.notification.title')} - ${translate(`ra.languages.${locale}`)}`}
                fullWidth
              />
            );
          })}

          {SupportedLanguages.map(locale => {
            return (
              <TextInput
                key={`body[${locale}]`}
                source={`body[${locale}]`}
                label={`${translate('ra.notification.body')} - ${translate(`ra.languages.${locale}`)}`}
                multiline
                rows={3}
                fullWidth
              />
            );
          })}

          <RadioButtonGroupInput
            label={translate('ra.notification.messageReceiveAction')}
            source="payload"
            defaultValue={'none'}
            choices={[
              { id: 'none', name: translate('ra.notification.action.none') },
              { id: 'manual', name: translate('ra.notification.action.goToManual') },
              { id: 'webpage', name: translate('ra.notification.action.goToWebSite') },
            ]}
            onChange={handleChangePayload}
          />
          {payload === 'manual' ? (
            <SelectInput
              label={translate('ra.notification.payload.manualPlaceholder')}
              source={'payload[manual]'}
              choices={manuals.map(manual => {
                console.log(manual);
                return {
                  id: `${manual.id}`,
                  name: manual.title[currentLocale],
                };
              })}
              fullWidth
            />
          ) : payload === 'webpage' ? (
            <TextInput
              key={`payload[webpage]`}
              source={`payload[webpage]`}
              label={translate('ra.notification.payload.webpagePlaceholder')}
              fullWidth
            />
          ) : undefined}
        </SimpleForm>
      </CardContent>
    </Card>
  );
};

export default Notifications;
