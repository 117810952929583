import React from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { Button, CardContent, CircularProgress } from '@mui/material';
import { Form, required, useTranslate, useSafeSetState } from 'ra-core';
import { FormGroupContextProvider, TextInput, useFormGroup, useNotify } from 'react-admin';
import { FieldValues } from 'react-hook-form';
import { findPasswordDealer } from '../../api/dealers';
// import BackIcon from '@mui/icons-material/ArrowBack';

export interface DealerFindPasswordFormProps {
  onBack: () => void;
}
export const DealerFindPasswordForm = (props: DealerFindPasswordFormProps) => {
  const { onBack } = props;
  const [loading, setLoading] = useSafeSetState(false);
  const [email, setEmail] = React.useState<string>('');
  const translate = useTranslate();
  const notify = useNotify();

  const submit = async () => {
    setLoading(true);
    const result = await findPasswordDealer(email);
    if (result) {
      setLoading(false);
      notify(translate('ra.auth.dealer.finishFindPW'));
      onBack();
    }
  };

  return (
    <StyledForm onSubmit={submit} mode="onChange" noValidate>
      <CardContent className={LoginFormClasses.content}>
        {/* <Button onClick={() => onBack()}>
          <BackIcon />
        </Button> */}
        <TextInput
          autoFocus
          source="email"
          label={translate('ra.auth.dealer.username')}
          validate={required('ra.auth.inputEmail')}
          onChange={e => setEmail(e.target.value)}
          fullWidth
        />

        <Button
          variant="contained"
          type="submit"
          color="primary"
          disabled={loading}
          fullWidth
          className={LoginFormClasses.button}
        >
          {loading ? (
            <CircularProgress className={LoginFormClasses.icon} size={19} thickness={3} />
          ) : (
            translate('ra.auth.dealer.findPassword')
          )}
        </Button>

        <Button
          variant="contained"
          type="button"
          color="info"
          disabled={loading}
          fullWidth
          className={LoginFormClasses.join}
          onClick={onBack}
        >
          {loading ? (
            <CircularProgress className={LoginFormClasses.icon} size={19} thickness={3} />
          ) : (
            translate('ra.auth.dealer.joinCancel')
          )}
        </Button>
      </CardContent>
    </StyledForm>
  );
};

const PREFIX = 'RaLoginForm';

const LoginFormClasses = {
  content: `${PREFIX}-content`,
  button: `${PREFIX}-button`,
  join: `${PREFIX}-button`,
  icon: `${PREFIX}-icon`,
  sendEmailVerify: `${PREFIX}-sendEmailVerify`,
};

const StyledForm = styled(Form, {
  name: PREFIX,
  overridesResolver: (props, styles) => styles.root,
})(({ theme }) => ({
  [`& .${LoginFormClasses.content}`]: {
    width: 350,
  },
  [`& .${LoginFormClasses.join}`]: {
    marginTop: theme.spacing(1),
    marignBottom: theme.spacing(1),
  },
  [`& .${LoginFormClasses.sendEmailVerify}`]: {
    marignBottom: theme.spacing(2),
  },
  [`& .${LoginFormClasses.button}`]: {
    marginTop: theme.spacing(2),
  },
  [`& .${LoginFormClasses.icon}`]: {
    margin: theme.spacing(0.3),
  },
}));

export interface LoginFormProps {
  redirectTo?: string;
  className?: string;
}

interface FormData {
  username: string;
  password: string;
}
DealerFindPasswordForm.propTypes = {
  redirectTo: PropTypes.string,
};
