import * as React from 'react';
import { memo, FC, ElementType } from 'react';
import get from 'lodash/get';
import Typography, { TypographyProps } from '@mui/material/Typography';
import { useRecordContext } from 'ra-core';
import { InjectedFieldProps, PublicFieldProps } from 'react-admin';

export interface JSONTextFieldProps extends PublicFieldProps, InjectedFieldProps, Omit<TypographyProps, 'textAlign'> {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  component?: ElementType<any>;
}

export const JSONTextField: FC<JSONTextFieldProps> = memo(props => {
  const { className, source, emptyText, ...rest } = props;
  const record = useRecordContext(props);
  const value = get(record, source ?? '');

  return (
    <Typography component="span" variant="body2" className={className} {...rest}>
      <pre>{!!value && typeof value === 'string' ? JSON.stringify(JSON.parse(value), undefined, 2) : emptyText}</pre>
    </Typography>
  );
});

// what? TypeScript loses the displayName if we don't set it explicitly
JSONTextField.displayName = 'JSONTextField';

JSONTextField.propTypes = {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  ...Typography.propTypes,
};
