export const Resources = {
  Admins: 'admins',
  Users: 'users',
  Dealers: 'dealers',
  Manuals: 'manuals',
  BovSounds: 'bovSounds',
  Notification: 'notification',
  Logs: 'logs',
  UsersForDealer: 'usersForDealer',
  DealersNotGrant: 'dealersNotGrant',
};

export const AuthType = {
  Dealer: 'dealer',
  Admin: 'admin',
};
