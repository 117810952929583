import { GetListParams, GetListResult } from 'ra-core';
import api from '.';
import { User } from '../typing/common';
import { ListResponse } from '../typing/common';
import { GetOneResult } from 'react-admin';

export const users = async (params: GetListParams, dealerKey?: string): Promise<GetListResult<User>> => {
  const {
    pagination: { page, perPage },
    sort: { field, order },
    filter: { q },
  } = params;
  const {
    data: { items, meta },
  }: ListResponse<User> = await api.get('users', {
    params: {
      page,
      size: perPage,
      sort: field,
      order,
      search: q,
      dealerKey,
    },
  });

  return {
    data: items,
    total: meta.totalItems,
    pageInfo: {
      hasNextPage: meta.totalPages > meta.currentPage,
      hasPreviousPage: meta.currentPage > 1,
    },
  };
};

export const user = async (id: number): Promise<GetOneResult<User>> => {
  const result = await api.get(`users/${id}`);
  return result as GetOneResult<User>;
};
