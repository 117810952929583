export const ko = {
  resources: {
    admins: {
      fields: {
        id: 'ID',
      },
    },
    users: { fields: { id: 'ID', title: '제목' } },
    manuals: { fields: { id: 'ID', title: '제목' } },
    dealers: {
      fields: {
        id: 'ID',
        name: '이름',
        email: '이메일',
        password: '비밀번호',
        passwordConfirm: '비밀번호 확인',
      },
    },
  },
  ra: {
    statusCodeKey: {
      // 관리자 로그인
      adminLoginSuccess: '',
      adminLoginFailed: '',
      adminLoginFailedNotFoundUser: '',
      // 딜러 로그인
      dealerLoginSuccess: '',
      dealerLoginFailed: '',
      dealerLoginFailedNotFoundUser: '',
      // 로그인
      loginSuccess: '',
      loginFailed: '',
      loginFailedNotFoundUser: '',
      // SNS 로그인
      snsLoginSuccess: '',
      snsLoginFailed: '',
      snsLoginFailedNotFoundUser: '',
      // 로그아웃
      logoutSuccess: '',
      logoutFailed: '',
      // CHECK AUTH
      checkAuthSuccess: '',
      checkAuthFailed: '',
      checkAuthFailedNotFoundUser: '',
      // Token
      tokenRefreshSuccess: '',
      tokenRefreshFailed: '',
      // 사용자 목록 조회
      getUserListSuccess: '',
      getUserListFailed: '',
      // 사용자 상세 조회
      getUserSuccess: '',
      getUserFailed: '',
      // 사용자 삭제
      deleteUserSuccess: '',
      deleteUserFailed: '',
      // 이메일 인증 코드 전송
      sendEmailAuthSuccess: '',
      sendEmailAuthFailed: '',
      // 이메일 인증 코드 체크
      checkEmailAuthSuccess: '',
      checkEmailAuthFailed: '',
      // 딜러 코드 확인
      checkDealerCodeSuccess: '',
      checkDealerCodeFailed: '',
      // 임시 비밀번호 전송( 비밀번호 변경 )
      sendTempPasswordSuccess: '',
      sendTempPasswordFailed: '',
      // 회원가입( 사용자 등록 )
      signupSuccess: '',
      signupFailed: '',
      signupFailedDuplicateEmail: '',
      signupFailedDuplicateNickname: '',
      // SNS 회원가입( 사용자 등록 )
      snsSignupSuccess: '',
      snsSignupFailed: '',
      snsSignupFailedDuplicateUid: '',
      snsSignupFailedDuplicateEmail: '',
      // 회원정보 수정
      modifyUserInfoSuccess: '',
      modifyUserInfoFailed: '',
      // 회원 설정 정보 수정
      modifyUserSettingInfoSuccess: '',
      modifyUserSettingInfoFailed: '',
      // 기기 등록
      registerDeviceSuccess: '',
      registerDeviceFailed: '',
      // 회원탈퇴
      withdrawalSuccess: '',
      withdrawalFailed: '',
      // Apple 로그인 사용자 이름 저장
      saveAppleUserNameSuccess: '',
      saveAppleUserNameFailed: '',
      // Apple 로그인 사용자 이름 조회
      getAppleUserNameSuccess: '',
      getAppleUserNameFailed: '',
      // 이미지 업로드
      uploadImageSuccess: '',
      uploadImageFailed: '',
      // 파일 업로드
      uploadFileSuccess: '',
      uploadFileFailed: '',
      // 파일 조회
      getFileSuccess: '',
      getFileFailed: '',
      // 파일 삭제
      deleteFileSuccess: '',
      deleteFileFailed: '',
      // 메뉴얼 전체 목록 조회
      getManualListSuccess: '',
      getManualListFailed: '',
      // 메뉴얼 조회
      getManualSuccess: '',
      getManualFailed: '',
      // BLE 기기 등록
      registerBleDeviceSuccess: '',
      registerBleDeviceFailed: '',
      // BLE 기기 조회
      getBleDeviceSuccess: '',
      getBleDeviceFailed: '',
      // 관리자 목록 조회
      getAdminListSuccess: '',
      getAdminListFailed: '',
      // 관리자 등록
      signupAdminSuccess: '',
      signupAdminFailed: '',
      // 관리자 조회
      getAdminSuccess: '',
      getAdminFailed: '',
      // 관리자 수정
      modifyAdminSuccess: '',
      modifyAdminFailed: '',
      // 관리자 삭제
      deleteAdminSuccess: '',
      deleteAdminFailed: '',
      // 가입 인증 메일 전송
      sendAuthEmailSuccess: '',
      sendAuthEmailFailed: '',
      // 딜러 가입
      signupDealerSuccess: '',
      signupDealerFailed: '',
      signupDealerFailedDuplicateEmail: '',
      signupDealerFailedDuplicateNickname: '',
      // 딜러 목록 조회
      getDealerListSuccess: '',
      getDealerListFailed: '',
      // 딜러 상세 조회
      getDealerSuccess: '',
      getDealerFailed: '',
      // 딜러 수정
      modifyDealerSuccess: '',
      modifyDealerFailed: '',
      // 딜러 삭제
      deleteDealerSuccess: '',
      deleteDealerFailed: '',
      // 딜러 가입 승인
      approveDealerSuccess: '',
      approveDealerFailed: '',
      // 딜러 패스워드 초기화
      resetDealerPasswordSuccess: '',
      resetDealerPasswordFailed: '',
      // 메뉴얼 등록
      registerManualSuccess: '',
      registerManualFailed: '',
      // 메뉴얼 수정
      modifyManualSuccess: '',
      modifyManualFailed: '',
      // 메뉴얼 삭제
      deleteManualSuccess: '',
      deleteManualFailed: '',
      // Bov 목록 조회
      getBovListSuccess: '',
      getBovListFailed: '',
      // Bov 등록
      registerBovSuccess: '',
      registerBovFailed: '',
      // Bov 상세 조회
      getBovSuccess: '',
      getBovFailed: '',
      // Bov 수정
      modifyBovSuccess: '',
      modifyBovFailed: '',
      // Bov 삭제
      deleteBovSuccess: '',
      deleteBovFailed: '',
      // 푸시 메시지 전송
      sendPushMessageSuccess: '',
      sendPushMessageFailed: '',
      // 푸시 상세 메시지 전송
      sendPushDetailMessageSuccess: '',
      sendPushDetailMessageFailed: '',
      // 푸시 상세 메시지 전송( 다건 )
      sendPushDetailMessagesSuccess: '',
      sendPushDetailMessagesFailed: '',
      // 기간별 가입자 현황 조회
      getDashboardSignupStatisticsSuccess: '',
      getDashboardSignupStatisticsFailed: '',
      // 전체 가입수 조회
      getDashboardSignupTotalSuccess: '',
      getDashboardSignupTotalFailed: '',
    },
    common: {
      // 공통
      add: '추가',
      modify: '수정',
      delete: '삭제',
      cancel: '취소',
      confirm: '확인',
      search: '검색',
    },
    auth: {
      username: '관리자 아이디 (이메일)',
      password: '비밀번호',
      dealerLogin: '딜러 로그인',
      adminLogin: '관리자 로그인',
      signIn: '로그인',
      logout: '로그아웃',
      inputEmail: '아이디를 입력해주세요.',
      inputPassword: '비밀번호를 입력해주세요.',
      inputPasswordConfirm: 'Please Input Password Confirm',
      signInError: '로그인에 실패 하였습니다. ',
      notGranted: '승인대기중 입니다.',
      notFound: '일치하는 아이디를 찾을 수 없습니다.',
      notMatchPassword: '패스워드가 일치하지 않습니다.',
      notMatchPasswordConfirm: '비밀번호 확인이 일치하지 않습니다.',
      cantDeleteSelf: '자기 자신은 삭제 할 수 없습니다. ',
      dealer: {
        username: '딜러 아이디 (이메일)',
        password: '비밀번호',
        join: '딜러 회원 가입',
        joinSuccess: '회원 가입 되었습니다. 최대한 빠른 시일 안에 승인 하도록 하겠습니다!',
        joinError: '회원가입에 실패 하였습니다. ',
        findPassword: '비밀번호 찾기',
        sendEmailKey: '이메일 확인 키 전송',
        emailVerifyKey: '이메일 확인 키',
        passwordConfirm: '비밀번호 확인',
        resetPassword: '비밀번호 변경',
        newPassword: '신규 비밀번호',
        newPasswordConfirm: '신규 비밀번호 확인',
        needToResetPasswordNotify: '임시 비밀번호로 로그인 되었습니다. 비밀번호를 변경해주세요',
        needToResetPasswordHelp1: '임시 비밀번호로 로그인 되었습니다.',
        needToResetPasswordHelp2: '비밀번호를 변경해주세요',

        name: '회사 이름',
        dealerName: '담당자 이름',
        phone: '전화번호',
        country: '국가',
        postcode: '우편번호',
        address: '주소',
        addressDetail: '상세 주소',
        businessLicenseNumber: '사업자 등록 번호',

        joinCancel: '취소',
        finishFindPW: '이메일로 임시 비밀번호가 전송 되었습니다.',

        // join
        checkEmail: '이메일 주소를 확인해주세요.',
        failSendEmail: '이메일 발송에 실패 하였습니다.',
        checkEmailVerifyKey: '이메일로 발송된 인증키를 확인해주세요.',
        failExistEmail: '이미 가입된 이메일 입니다.',
        inputPasswordMoreEight: '패스워드는 8자 이상 입력해주세요.',
        notMatchPasswordConfirm: '패스워드 확인이 일치하지 않습니다.',
        inputEmail: '이메일을 입력해주세요.',
        inputEmailVerifyKey: '이메일 확인키를 입력해주세요.',
        inputPassword: '패스워드를 입력해주세요.',
        inputPasswordConfirm: '패스워드 확인을 입력해주세요.',
        inputName: '이름을 입력해주세요.',
        inputDealerName: '딜러명을 입력해주세요.',
        inputPhone: '전화번호를 입력해주세요.',
        inputCountry: '국가를 선택해주세요.',
        inputZipNumber: '우편번호를 입력해주세요.',
        inputAddress: '주소를 입력해주세요.',
        inputAddressDetail: '상세주소를 입력해주세요.',
        inputCompanyRegistrationNumber: '사업자 등록번호를 입력해주세요.',
      },
    },
    user: {
      search: '검색',
      id: '아이디',
      name: '이름',
      email: '이메일',
      snsType: 'SNS 유형',
      phone: '전화번호',
      createDate: '가입일자',
      joinRoute: '가입경로',
      deviceName: 'BLOWSOME 기기명',
      installDate: '설치일',
      warrantDate: '워런티',
      deviceSettings: '기기 설정 정보',
    },
    admin: {
      name: '이름',
      email: '이메일',
      createDate: '가입일자',
      adminAccount: '관리자 계정',
      password: '비밀번호',
      passwordConfirm: '비밀번호 확인',
      newPassword: '새 비밀번호',
      newPasswordConfirm: '새 비밀번호 확인',
    },
    bov: {
      createDate: '생성일자',
      title: 'BOV 사운드',
      name: '이름',
      fileName: '파일명(모듈)',
      displayName: '화면 표시명',
      isEnabled: '활성화',
    },
    dealers: {
      search: '검색',
      approveRegist: '가입 승인',
      notExistPendingApproveRegistDealer: '가입 승인 대기 중인 딜러가 없습니다.',
      confirmApproveRegist: '가입 승인 하시겠습니까?',
      successApproveRegist: '딜러의 가입이 승인 되었습니다.',
      shopName: '회사 이름',
      dealerName: '담당자 이름',
      phone: '전화번호',
      createDate: '가입일자',
      approve: '승인',
      approveDate: '승인일자',
      // create
      createDealerAccount: '딜러 계정 생성',
      email: '이메일',
      name: '이름',
      password: '비밀번호',
      passwordConfirm: '비밀번호 확인',
      // edit
      modifyDealerAccount: '딜러 계정 수정',
      shopCode: 'Shop Code',
      companyName: '상호명',
      businessLicenseNumber: '사업자 등록 번호',
      country: '국가',
      zipCode: '우편번호',
      address: '주소',
      addressDetail: '상세 주소',
    },
    notification: {
      title: '제목',
      body: '본문',
      messageReceiveAction: '메세지 수신 동작',
      action: {
        none: '아무것도 하지 않음',
        goToManual: '매뉴얼 화면으로 이동',
        goToWebSite: '웹 사이트 링크로 이동',
      },
      payload: {
        manualPlaceholder: '매뉴얼 선택 (최근 15개)',
        webpagePlaceholder: '웹 페이지 주소 입력',
      },
      confirmSend: '전체 사용자에게 메세지를 발송 하시겠습니까?',
    },
    manual: {
      title: '제목',
      content: '본문',
      order: '정렬 순서',
      imageDragGuide: '아이콘 이미지를 드래그하여 올리면 업로드',
      pdfDragGuide: 'PDF파일을 드래그하거나 선택해서 업로드',
      createDate: '생성일자',
    },
    validation: { required: '필수 항목을 입력 해주세요.' },
    side: {
      admins: '관리자',
      users: '사용자',
      dealers: '딜러',
      dealerRequest: '딜러 승인',
      manuals: '매뉴얼',
      bovSounds: 'BOV 사운드',
      notifications: '푸시 메세지',
      updateLog: '업데이트 로그',
      usersDealer: '사용자',
    },
    page: {
      dashboard: '대시보드',
    },
    action: {
      create: '추가',
      export: 'CSV 다운로드',
      edit: '수정',
      save: '저장',
      delete: '삭제',
      sendNotification: '발송',
    },
    navigation: {
      pageRowsPerPage: '페이지당 행 : ',
      pageRangeInfo: '페이지',
    },
    tiptap: {
      paragraph: '문단',
      heading1: 'H1',
      heading2: 'H2',
      heading3: 'H3',
      heading4: 'H4',
      heading5: 'H5',
      heading6: 'H6',
    },
    languages: {
      ko: '한국어',
      en: '영어',
    },
    settingLanguage: {
      ko: '한국어',
      en: 'English',
    },
  },
};
