// in src/App.js
import * as React from 'react';
import './App.css';
import { Admin, Authenticated, CustomRoutes, Resource, useTranslate } from 'react-admin';
import UserIcon from '@mui/icons-material/Group';
import DealerIcon from '@mui/icons-material/AssignmentInd';
import DealerRequestIcon from '@mui/icons-material/AssignmentTurnedIn';
import ArticleIcon from '@mui/icons-material/Article';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import EditNotificationsIcon from '@mui/icons-material/EditNotifications';
import { Route } from 'react-router-dom';
import authProvider from './providers/authProvider';
import dataProvider from './providers/dataProvider';
import { AuthType, Resources } from './common/defines';
import { UserList, UserShow } from './pages/users/';
import { AdminList, AdminCreate, AdminEdit } from './pages/admin';
import { DealerList, DealerShow } from './pages/dealers';
import { ManualList, ManualCreate, ManualEdit, ManualDetail } from './pages/manuals';
import Notifications from './pages/notifications';
import { Layout } from './pages/layouts/layout';
import { LoginPage } from './pages/login';
import { i18nProvider } from './common/i18nProvider';
import Dashboard from './pages/dashboard';
import { BovList, BovCreate, BovEdit } from './pages/bov';
import { DealerRequestList, DealerRequestShow } from './pages/dealerRequest';

// const dataProvider = jsonServerProvider('https://jsonplaceholder.typicode.com');

const App = () => {
  const translate = useTranslate();
  return (
    <Admin
      basename="BLOWSOME"
      dashboard={Dashboard}
      loginPage={LoginPage}
      authProvider={authProvider}
      dataProvider={dataProvider}
      i18nProvider={i18nProvider}
      layout={Layout}
    >
      <Resource
        name={Resources.Admins}
        list={AdminList}
        create={AdminCreate}
        edit={AdminEdit}
        icon={AdminPanelSettingsIcon}
        options={{
          label: translate('ra.side.admins'),
          permissions: AuthType.Admin,
        }}
      />
      <Resource
        name={Resources.Users}
        list={UserList}
        show={UserShow}
        icon={UserIcon}
        options={{
          label: translate('ra.side.users'),
          permissions: AuthType.Admin,
        }}
      />
      <Resource
        name={Resources.Dealers}
        list={DealerList}
        icon={DealerIcon}
        show={DealerShow}
        options={{
          label: translate('ra.side.dealers'),
          permissions: AuthType.Admin,
        }}
      />
      <Resource
        name={Resources.DealersNotGrant}
        list={DealerRequestList}
        icon={DealerRequestIcon}
        show={DealerRequestShow}
        options={{
          label: translate('ra.side.dealerRequest'),
          permissions: AuthType.Admin,
        }}
      />
      <Resource
        name={Resources.Manuals}
        list={ManualList}
        create={ManualCreate}
        edit={ManualEdit}
        show={ManualDetail}
        icon={ArticleIcon}
        options={{
          label: translate('ra.side.manuals'),
          permissions: AuthType.Admin,
        }}
      />
      <Resource
        name={Resources.BovSounds}
        list={BovList}
        create={BovCreate}
        edit={BovEdit}
        icon={VolumeUpIcon}
        options={{
          label: translate('ra.side.bovSounds'),
          permissions: AuthType.Admin,
        }}
      />
      <Resource
        name={Resources.Notification}
        icon={EditNotificationsIcon}
        options={{
          label: translate('ra.side.notifications'),
          permissions: AuthType.Admin,
        }}
      />
      {/* <Resource
        name={Resources.Logs}
        list={PostList}
        icon={FormatListBulletedIcon}
        options={{
          label: translate('ra.side.updateLog'),
          permissions: AuthType.Admin,
        }}
      /> */}
      <Resource
        name={Resources.UsersForDealer}
        list={UserList}
        show={UserShow}
        icon={UserIcon}
        options={{
          label: translate('ra.side.usersDealer'),
          permissions: AuthType.Dealer,
        }}
      />
      <CustomRoutes>
        <Route
          path="/notification"
          element={
            <Authenticated>
              <Notifications />
            </Authenticated>
          }
        />
      </CustomRoutes>
    </Admin>
  );
};

export default App;
