import React from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { Button, CardContent, CircularProgress } from '@mui/material';
import { Form, required, useTranslate, useLogin, useSafeSetState } from 'ra-core';
import { TextInput, useNotify } from 'react-admin';
import { FieldValues } from 'react-hook-form';
import { DealerJoinForm } from './DealerJoinForm';
import { AuthType } from '../../common/defines';
import { DealerFindPasswordForm } from './DealerFindPasswordForm';
import { LoginPageMode } from '.';
import { useHandleError } from '../../hooks/useHandleError';
import { AdminAuthResult } from '../../api/auth';
import { DealerPasswordResetForm } from './DealerPasswordResetForm';

export const DealerLoginForm = (props: LoginFormProps) => {
  console.log('DealerLoginForm', props);
  const { redirectTo, className, mode, onChangeMode } = props;
  const [loading, setLoading] = useSafeSetState(false);
  const login = useLogin();
  const translate = useTranslate();
  const notify = useNotify();
  const handleError = useHandleError('ra.auth.signInError');
  const [needToResetPW, setNeedToResetPW] = React.useState<AdminAuthResult>();

  const submit = async (values: FieldValues) => {
    setLoading(true);
    try {
      await login({ ...values, mode: AuthType.Dealer }, redirectTo);
      document.title = 'BLOWSOME Dealer';
    } catch (e) {
      if (typeof e === 'object' && (e as AdminAuthResult)?.payload?.isTempPassword) {
        onChangeMode('dealer_reset_pw');
        notify(translate('ra.auth.dealer.needToResetPasswordNotify'));
        setNeedToResetPW(e as AdminAuthResult);
        // u8pfJbpd
      } else {
        handleError(e);
      }
    } finally {
      setLoading(false);
    }
  };

  if (needToResetPW) {
    return <DealerPasswordResetForm authResult={needToResetPW} />;
  }
  if (mode === 'dealer_join') {
    return <DealerJoinForm onBack={() => onChangeMode('dealer')} />;
  }
  if (mode === 'dealer_find_pw') {
    return <DealerFindPasswordForm onBack={() => onChangeMode('dealer')} />;
  }

  return (
    <StyledForm onSubmit={submit} mode="onChange" noValidate className={className}>
      <CardContent className={LoginFormClasses.content}>
        <TextInput
          autoFocus
          source="username"
          label={translate('ra.auth.dealer.username')}
          validate={required('ra.auth.inputEmail')}
          fullWidth
        />
        <TextInput
          source="password"
          label={translate('ra.auth.dealer.password')}
          type="password"
          autoComplete="current-password"
          validate={required('ra.auth.inputPassword')}
          fullWidth
        />

        <Button
          variant="contained"
          type="submit"
          color="primary"
          disabled={loading}
          fullWidth
          className={LoginFormClasses.button}
        >
          {loading ? (
            <CircularProgress className={LoginFormClasses.icon} size={19} thickness={3} />
          ) : (
            translate('ra.auth.signIn')
          )}
        </Button>

        <Button
          variant="contained"
          type="button"
          color="info"
          disabled={loading}
          fullWidth
          className={LoginFormClasses.join}
          onClick={() => onChangeMode('dealer_join')}
        >
          {loading ? (
            <CircularProgress className={LoginFormClasses.icon} size={19} thickness={3} />
          ) : (
            translate('ra.auth.dealer.join')
          )}
        </Button>
        <Button
          variant="contained"
          type="button"
          color="info"
          disabled={loading}
          fullWidth
          className={LoginFormClasses.join}
          onClick={() => onChangeMode('dealer_find_pw')}
        >
          {loading ? (
            <CircularProgress className={LoginFormClasses.icon} size={19} thickness={3} />
          ) : (
            translate('ra.auth.dealer.findPassword')
          )}
        </Button>

        <a href="http://shop.xhaust-admin.com" target="blank">
          <Button
            variant="outlined"
            type="button"
            color="info"
            disabled={loading}
            fullWidth
            className={LoginFormClasses.join}
          >
            {loading ? (
              <CircularProgress className={LoginFormClasses.icon} size={19} thickness={3} />
            ) : (
              'XHAUST SHOP'
              // translate('ra.auth.dealer.findPassword')
            )}
          </Button>
        </a>
      </CardContent>
    </StyledForm>
  );
};

const PREFIX = 'RaLoginForm';

const LoginFormClasses = {
  content: `${PREFIX}-content`,
  button: `${PREFIX}-button`,
  join: `${PREFIX}-button`,
  icon: `${PREFIX}-icon`,
};

const StyledForm = styled(Form, {
  name: PREFIX,
  overridesResolver: (props, styles) => styles.root,
})(({ theme }) => ({
  [`& .${LoginFormClasses.content}`]: {
    width: 350,
  },
  [`& .${LoginFormClasses.button}`]: {
    marginTop: theme.spacing(2),
  },
  [`& .${LoginFormClasses.icon}`]: {
    margin: theme.spacing(0.3),
  },
}));

export interface LoginFormProps extends LoginPageMode {
  redirectTo?: string;
  className?: string;
}

interface FormData {
  username: string;
  password: string;
}
DealerLoginForm.propTypes = {
  redirectTo: PropTypes.string,
};
