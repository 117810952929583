import api from '.';
import { AuthType } from '../common/defines';

export interface AdminUser {
  id: number;
  email: string;
  name: string;
  role: string;
  dealerKey: string;
  createDate: string;
  updateDate: string;
  isTempPassword: boolean;
}
export interface DealerUser {
  id: number;
  email: string;
  name: string;
  role: string;
  dealerKey: string;
  createDate: string;
  updateDate: string;
  isTempPassword: boolean;
}
export interface AdminAuthResult {
  payload: AdminUser | DealerUser;
  accessToken: string;
  refreshToken: string;
  authType: 'admin' | 'dealer';
}

export const authAdmin = async ({ email, password }: { email: string; password: string }): Promise<AdminAuthResult> => {
  const result = await api.post('auth/login-admin', { email, password });
  return { ...result.data, authType: AuthType.Admin } as AdminAuthResult;
};

export const authDealer = async ({
  email,
  password,
}: {
  email: string;
  password: string;
}): Promise<AdminAuthResult> => {
  const result = await api.post('auth/login-dealer', { email, password });
  return { ...result.data, authType: AuthType.Dealer } as AdminAuthResult;
};

export interface AdminUserIdentifier extends AdminUser {
  fullName: string;
  authType: 'admin' | 'dealer';
}
