import {
  Button,
  Datagrid,
  DateField,
  EditButtonProps,
  EmailField,
  List,
  Show,
  SimpleShowLayout,
  TextField,
  TextInput,
  useNotify,
  useRecordContext,
  useRefresh,
  useTranslate,
} from 'react-admin';

import GrantIcon from '@mui/icons-material/Key';
import { grantDealer } from '../../api/dealers';
import { Dealer } from '../../typing/common';
import { Resources } from '../../common/defines';

const dealerFilters = [<TextInput label="ra.dealers.search" source="q" alwaysOn />];

const GrantButton = <RecordType extends Dealer>(props: EditButtonProps<RecordType>) => {
  const refresh = useRefresh();
  const notify = useNotify();
  const record = useRecordContext<Dealer>(props);
  const translate = useTranslate();

  if (!record) return null;
  if (record.isGranted) return null;
  return (
    <Button
      variant="contained"
      label="ra.dealers.approveRegist"
      onClick={async e => {
        e.preventDefault();
        e.stopPropagation();
        console.log(record);
        if (!confirm(translate('ra.dealers.confirmApproveRegist'))) return;
        await grantDealer(record.id);
        refresh();
        notify(`${record.name} ${translate('ra.dealers.successApproveRegist')}`);
        location.href = '../';
      }}
    >
      <GrantIcon />
    </Button>
  );
};

export const DealerRequestList = () => {
  const translate = useTranslate();
  return (
    <List
      sort={{ field: 'id', order: 'DESC' }}
      filters={dealerFilters}
      resource={Resources.DealersNotGrant}
      title={''}
      empty={<span>{translate('ra.dealers.notExistPendingApproveRegistDealer')}</span>}
    >
      <Datagrid rowClick="show">
        <TextField source="id" />
        <TextField source="name" label="ra.dealers.shopName" />
        <TextField source="dealerName" label="ra.dealers.dealerName" />
        <TextField source="phone" label="ra.dealers.phone" />
        <EmailField source="email" label="ra.dealers.email" />
        <DateField source="createDate" label="ra.dealers.createDate" />
        <GrantButton label="ra.dealers.approve" />
      </Datagrid>
    </List>
  );
};

export const DealerRequestShow = () => (
  <Show emptyWhileLoading>
    <SimpleShowLayout>
      <TextField label="ID" source="id" />
      <TextField source="name" label="ra.dealers.shopName" />
      <TextField source="dealerName" label="ra.dealers.dealerName" />
      <TextField source="phone" label="ra.dealers.phone" />
      <EmailField source="email" label="ra.dealers.email" />
      <TextField source="country" label="ra.dealers.country" />
      <TextField source="postcode" label="ra.dealers.zipCode" />
      <TextField source="address" label="ra.dealers.address" />
      <TextField source="addressDetail" label="ra.dealers.addressDetail" />
      <TextField source="businessLicenseNumber" label="ra.dealers.businessLicenseNumber" />
      <DateField source="createDate" label="ra.dealers.createDate" />
      <GrantButton label="ra.dealers.approve" />
    </SimpleShowLayout>
  </Show>
);
