import * as React from 'react';
import { Login, useI18nProvider, useTranslate } from 'react-admin';
import { CardContent, ToggleButtonGroup, ToggleButton as MuiToggleButton } from '@mui/material';
import { AdminLoginForm } from './AdminLoginForm';
import { DealerLoginForm } from './DealerLoginForm';
import { LoginMode } from '../../typing/common';
import { styled } from '@mui/material/styles';

const ToggleButton = styled(MuiToggleButton)({
  '&.Mui-selected, &.Mui-selected:hover': {
    color: 'white',
    backgroundColor: '#1976d2',
  },
});

export interface LoginPageMode {
  mode: LoginMode;
  onChangeMode: (mode: LoginMode) => void;
}

export const LoginPage = () => {
  const i18n = useI18nProvider();
  const [mode, setMode] = React.useState<LoginMode>((localStorage.getItem('mode') ?? 'dealer') as LoginMode);
  const [locale, setLocale] = React.useState(i18n.getLocale());
  const translate = useTranslate();

  React.useEffect(() => {
    localStorage.setItem('mode', mode);
  }, [mode]);
  const handleChange = (_: React.MouseEvent<HTMLElement>, value: 'admin' | 'dealer') => {
    if (value === null) return;
    setMode(value);
  };

  const handleChangeLanguage = React.useCallback(() => {
    const updateLocale = i18n.getLocale() === 'ko' ? 'en' : 'ko';
    i18n.changeLocale(updateLocale);
    setLocale(updateLocale);
  }, [i18n]);

  return (
    <Login>
      <h1 style={{ textAlign: 'center' }}>BLOWSOME</h1>

      <ToggleButtonGroup
        orientation="horizontal"
        value={locale}
        exclusive
        // fullWidth
        style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
        onChange={handleChangeLanguage}
      >
        <ToggleButton style={{ minWidth: 100, height: 30 }} value="ko">
          {translate('ra.settingLanguage.ko')}
        </ToggleButton>
        <ToggleButton style={{ minWidth: 100, height: 30 }} value="en">
          {translate('ra.settingLanguage.en')}
        </ToggleButton>
      </ToggleButtonGroup>
      {(mode === 'admin' || mode === 'dealer') && (
        <CardContent>
          <ToggleButtonGroup orientation="horizontal" value={mode} exclusive fullWidth onChange={handleChange}>
            <ToggleButton value="dealer" aria-label="module">
              {translate('ra.auth.dealerLogin')}
            </ToggleButton>
            <ToggleButton value="admin" aria-label="admin">
              {translate('ra.auth.adminLogin')}
            </ToggleButton>
          </ToggleButtonGroup>
        </CardContent>
      )}
      {mode === 'admin' ? <AdminLoginForm /> : <DealerLoginForm mode={mode} onChangeMode={setMode} />}
    </Login>
  );
};
