import { apiHost } from './index';
import { CreateManualDto, UpdateManualDto } from './../typing/common';
import { GetListParams, GetListResult } from 'ra-core';
import api from '.';
import { Manual } from '../typing/common';
import { ListResponse } from '../typing/common';
import {
  CreateParams,
  CreateResult,
  DeleteManyParams,
  DeleteManyResult,
  DeleteParams,
  DeleteResult,
  GetOneResult,
  UpdateParams,
  UpdateResult,
} from 'react-admin';

export const manuals = async ({
  pagination: { page, perPage },
  sort: { field, order },
}: GetListParams): Promise<GetListResult<Manual>> => {
  const params = {
    page,
    size: perPage,
    sort: field,
    order,
  };
  console.log(params);
  const {
    data: { items, meta },
  }: ListResponse<Manual> = await api.get('manuals', {
    params,
  });

  const result = {
    data: items.map(item => {
      return {
        ...item,
        // icon: item.icon ? `${apiHost}/${item.icon}` : item.icon,
        // pdf: item.pdf ? `${apiHost}/${item.pdf}` : item.pdf,
        icon: undefined,
        iconFiles: item.pdfFile ? [{ ...item.pdfFile, src: `${apiHost}/${item.pdf}` }] : undefined,
        pdf: undefined,
        pdfFiles: item.pdfFile ? [{ ...item.pdfFile, src: `${apiHost}/${item.pdf}` }] : undefined,
        // pdf: item.pdfFile
        //   ? [{ ...item.pdfFile, title: item?.pdfFile?.originalname, src: `${apiHost}/${item.pdf}` }]
        //   : undefined,
      };
    }),
    total: meta.totalItems,
    pageInfo: {
      hasNextPage: meta.totalPages > meta.currentPage,
      hasPreviousPage: meta.currentPage > 1,
    },
  };
  return result;
};

export const manual = async (id: number): Promise<GetOneResult<Manual>> => {
  const { data: item } = await api.get(`manuals/${id}`);
  const result = {
    data: {
      ...item,
      icon: item.icon ? `${apiHost}/${item.icon}` : item.icon,
      // pdf: item.pdf ? `${apiHost}/${item.pdf}` : item.pdf,
      pdf: undefined,
      pdfFiles: item.pdfFile ? [{ ...item.pdfFile, src: `${apiHost}/${item.pdf}` }] : undefined,
      // pdf: item.pdf
      //   ? [{ ...item.pdfFile, title: item.pdfFile.originalname, src: `${apiHost}/${item.pdfFile.filename}` }]
      //   : undefined,
    },
  } as GetOneResult<Manual>;

  console.log('manual', result);
  return result;
};

export const createManual = async ({ data }: CreateParams<CreateManualDto>): Promise<CreateResult<Manual>> => {
  console.log('createManual', data);

  const form = new FormData();
  form.append('title', JSON.stringify(data.title));
  if (data.youtube) form.append('youtube', data.youtube);
  form.append('order', `${data.order ?? 0}`);
  form.append('content', JSON.stringify(data.content));
  if (data.icon) form.append('icon', data.icon.rawFile);
  if (data.pdf) form.append('pdf', data.pdf.rawFile);

  // formData.append('icon', )

  const result = await api.post('manuals', form, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
  return result as CreateResult<Manual>;
};

export const updateManual = async ({ data }: UpdateParams<UpdateManualDto>): Promise<CreateResult<Manual>> => {
  console.log('updateManual', data);

  const form = new FormData();
  form.append('title', JSON.stringify(data.title));
  if (data.youtube) form.append('youtube', data.youtube);
  form.append('order', `${data.order ?? 0}`);
  form.append('content', JSON.stringify(data.content));
  if (data.icon) form.append('icon', data.icon.rawFile);
  if (data.pdf) form.append('pdf', data.pdf.rawFile);

  // formData.append('icon', )

  const result = await api.patch(`manuals/${data.id}`, form, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
  return result as UpdateResult<Manual>;
};

export const deleteManual = async ({ id }: DeleteParams<Manual>): Promise<DeleteResult> => {
  // const { payload: self } = await getCurrentAuth();
  // console.log(self.id, id);

  const result = await api.delete(`manuals/${id}`);
  return { data: true } as DeleteResult;
};

export const deleteManuals = async ({ ids }: DeleteManyParams<Manual>): Promise<DeleteManyResult> => {
  // const { payload: self } = await getCurrentAuth();
  // console.log(self.id, ids);

  const result = await api.delete(`manuals/${ids.join(',')}`);
  return {} as DeleteManyResult;
};
