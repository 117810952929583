import * as React from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { Button, CardContent, CircularProgress } from '@mui/material';
import { Form, required, useTranslate, useLogin, useSafeSetState } from 'ra-core';
import { TextInput } from 'react-admin';
import { FieldValues } from 'react-hook-form';
import { AuthType } from '../../common/defines';
import { useHandleError } from '../../hooks/useHandleError';

export const AdminLoginForm = (props: LoginFormProps) => {
  const { redirectTo, className } = props;
  const [loading, setLoading] = useSafeSetState(false);
  const login = useLogin();
  const translate = useTranslate();
  const handleError = useHandleError('ra.auth.signInError');

  const submit = async (values: FieldValues) => {
    setLoading(true);
    try {
      await login({ ...values, mode: AuthType.Admin }, redirectTo);
      document.title = 'BLOWSOME Admin';
    } catch (e) {
      handleError(e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <StyledForm onSubmit={submit} mode="onChange" noValidate className={className}>
      <CardContent className={LoginFormClasses.content}>
        <TextInput
          autoFocus
          source="username"
          label={translate('ra.auth.username')}
          validate={required('ra.auth.inputEmail')}
          fullWidth
        />
        <TextInput
          source="password"
          label={translate('ra.auth.password')}
          type="password"
          autoComplete="current-password"
          validate={required('ra.auth.inputPassword')}
          fullWidth
        />

        <Button
          variant="contained"
          type="submit"
          color="primary"
          disabled={loading}
          fullWidth
          className={LoginFormClasses.button}
        >
          {loading ? (
            <CircularProgress className={LoginFormClasses.icon} size={19} thickness={3} />
          ) : (
            translate('ra.auth.signIn')
          )}
        </Button>
        <a href="http://xhaust-admin.com/member/login.php" target="blank">
          <Button variant="outlined" color="primary" disabled={loading} fullWidth className={LoginFormClasses.button}>
            {loading ? <CircularProgress className={LoginFormClasses.icon} size={19} thickness={3} /> : 'Xhaust Admin'}
          </Button>
        </a>
      </CardContent>
    </StyledForm>
  );
};

const PREFIX = 'RaLoginForm';

export const LoginFormClasses = {
  content: `${PREFIX}-content`,
  button: `${PREFIX}-button`,
  icon: `${PREFIX}-icon`,
};

const StyledForm = styled(Form, {
  name: PREFIX,
  overridesResolver: (props, styles) => styles.root,
})(({ theme }) => ({
  [`& .${LoginFormClasses.content}`]: {
    width: 350,
  },
  [`& .${LoginFormClasses.button}`]: {
    marginTop: theme.spacing(2),
  },
  [`& .${LoginFormClasses.icon}`]: {
    margin: theme.spacing(0.3),
  },
}));

export interface LoginFormProps {
  redirectTo?: string;
  className?: string;
}

AdminLoginForm.propTypes = {
  redirectTo: PropTypes.string,
};
