// in src/Dashboard.js
import React from 'react';
import { Card, CardContent, CardHeader } from '@mui/material';

import Typography, { TypographyProps } from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Link from '@mui/material/Link';
import Chart from './Chart';
import TotalUsers from './TotalUsers';
import Orders from './Orders';

import { useAuthState, usePermissions } from 'react-admin';
import { QRCodeCanvas } from 'qrcode.react';
import { getCurrentAuth } from '../../providers/authProvider';

function Copyright(props: TypographyProps) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://blowsome.app/">
        BLOWSOME
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

export default function Dashboard(): React.ReactElement {
  const { permissions } = usePermissions();
  const authState = useAuthState();
  console.log(permissions, authState);
  const isDealer = permissions === 'dealer';
  const auth = getCurrentAuth();
  if (isDealer) {
    return (
      <Card>
        <CardHeader title={`BLOWSOME Dealer`} />
        <CardContent>
          <h2>Shop Code : {auth?.payload?.dealerKey}</h2>
          {auth?.payload?.dealerKey && <QRCodeCanvas size={300} value={auth?.payload?.dealerKey ?? ''} />}
        </CardContent>
      </Card>
    );
  }
  return (
    <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
      {/* <CardHeader title={`BLOWSOME Admin`} /> */}
      <Grid container spacing={3}>
        {/* Chart */}
        <Grid item xs={12} md={8} lg={9}>
          <Paper
            sx={{
              p: 2,
              display: 'flex',
              flexDirection: 'column',
              height: 240,
            }}
          >
            <Chart />
          </Paper>
        </Grid>
        {/* Recent Deposits */}
        <Grid item xs={12} md={4} lg={3}>
          <Paper
            sx={{
              p: 2,
              display: 'flex',
              flexDirection: 'column',
              height: 240,
            }}
          >
            <TotalUsers />
          </Paper>
        </Grid>
        {/* Recent Orders
        <Grid item xs={12}>
          <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
            <Orders />
          </Paper>
        </Grid> */}
      </Grid>
      <Copyright sx={{ pt: 4 }} />
    </Container>
  );
}
