import {
  CreateParams,
  CreateResult,
  DataProvider,
  DeleteManyParams,
  DeleteManyResult,
  DeleteParams,
  DeleteResult,
  GetListParams,
  GetListResult,
  GetManyParams,
  GetManyReferenceParams,
  GetManyReferenceResult,
  GetManyResult,
  GetOneParams,
  GetOneResult,
  UpdateManyParams,
  UpdateManyResult,
  UpdateParams,
  UpdateResult,
} from 'ra-core';
import { Resources } from '../common/defines';
import { user, users } from '../api/users';
import { admin, admins, createAdmin, deleteAdmin, deleteAdmins, updateAdmin } from '../api/admins';
import { bov, bovs, createBov, deleteBov, deleteBovs, updateBov } from '../api/bov';
import { dealer, dealers, deleteDealer, deleteDealers, updateDealers } from './../api/dealers';
import { createManual, deleteManual, deleteManuals, manual, manuals, updateManual } from './../api/manuals';
import { getCurrentAuth } from './authProvider';

const dataProvider: DataProvider<string> = {
  getList: (resource: string, params: GetListParams): Promise<GetListResult> => {
    console.log('getList', resource, params);
    if (resource === Resources.Users) return users(params);
    if (resource === Resources.Admins) return admins(params);
    if (resource === Resources.Dealers) return dealers(params, true);
    if (resource === Resources.DealersNotGrant) return dealers(params, false);
    if (resource === Resources.Manuals) return manuals(params);
    if (resource === Resources.BovSounds) return bovs(params);
    if (resource === Resources.UsersForDealer) {
      const auth = getCurrentAuth();
      return users(params, auth?.payload?.dealerKey);
    }
    return Promise.resolve({ data: [{ id: 1, isEmpty: true }], total: 1 });
  },
  getOne: (resource: string, params: GetOneParams): Promise<GetOneResult> => {
    // return Promise<GetOneResult<RecordType>>
    console.log('getOne', resource, params);
    if (resource === Resources.Users) return user(params.id);
    if (resource === Resources.UsersForDealer) return user(params.id);
    if (resource === Resources.Admins) return admin(params.id);
    if (resource === Resources.Dealers) return dealer(params.id);
    if (resource === Resources.DealersNotGrant) return dealer(params.id);
    if (resource === Resources.Manuals) return manual(params.id);
    if (resource === Resources.BovSounds) return bov(params.id);
    return Promise.reject();
  },
  getMany: (resource: string, params: GetManyParams): Promise<GetManyResult> => {
    // return Promise<GetManyResult<RecordType>>
    console.log('getMany', resource, params);
    return Promise.reject();
  },
  getManyReference: (resource: string, params: GetManyReferenceParams): Promise<GetManyReferenceResult> => {
    // return Promise<GetManyReferenceResult<RecordType>>
    console.log('getManyReference', resource, params);
    return Promise.reject();
  },
  update: (resource: string, params: UpdateParams): Promise<UpdateResult> => {
    // return Promise<UpdateResult<RecordType>>
    console.log('update', resource, params);
    if (resource === Resources.Admins) return updateAdmin(params);
    if (resource === Resources.Dealers) return updateDealers(params);
    if (resource === Resources.Manuals) return updateManual(params);
    if (resource === Resources.BovSounds) return updateBov(params);
    return Promise.reject();
  },
  updateMany: (resource: string, params: UpdateManyParams): Promise<UpdateManyResult> => {
    // return Promise<UpdateManyResult<RecordType>>
    console.log('updateMany', resource, params);
    return Promise.reject();
  },
  create: (resource: string, params: CreateParams): Promise<CreateResult> => {
    // return Promise<CreateResult<RecordType>>
    console.log('create', resource, params);
    if (resource === Resources.Admins) return createAdmin(params);
    if (resource === Resources.Manuals) return createManual(params);
    if (resource === Resources.BovSounds) return createBov(params);
    return Promise.reject('not implemented');
  },
  delete: async (resource: string, params: DeleteParams): Promise<DeleteResult> => {
    // return Promise<DeleteResult<RecordType>>
    console.log('delete', resource, params);
    if (resource === Resources.Admins) return deleteAdmin(params);
    if (resource === Resources.Dealers) return deleteDealer(params);
    if (resource === Resources.Manuals) return deleteManual(params);
    if (resource === Resources.BovSounds) return deleteBov(params);
    return Promise.reject();
  },
  deleteMany: (resource: string, params: DeleteManyParams): Promise<DeleteManyResult> => {
    // return Promise<DeleteManyResult<RecordType>>
    console.log('deleteMany', resource, params);
    if (resource === Resources.Admins) return deleteAdmins(params);
    if (resource === Resources.Dealers) return deleteDealers(params);
    if (resource === Resources.Manuals) return deleteManuals(params);
    if (resource === Resources.BovSounds) return deleteBovs(params);

    return Promise.reject();
  },
};

export default dataProvider;
