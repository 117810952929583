import {
  BooleanField,
  BooleanInput,
  CreateBase,
  Datagrid,
  DateField,
  EditBase,
  List,
  required,
  SelectField,
  SelectInput,
  SimpleForm,
  TextField,
  TextInput,
} from 'react-admin';
import { Card } from '@mui/material';
import { RecordTitle } from '../../component/RecordTitle';
const BovChoices = [
  { id: 'bov1', name: 'Bov 1' },
  { id: 'bov2', name: 'Bov 2' },
  { id: 'bov3', name: 'Bov 3' },
  { id: 'bov4', name: 'Bov 4' },
  { id: 'bov5', name: 'Bov 5' },
  { id: 'bov6', name: 'Bov 6' },
  { id: 'bov7', name: 'Bov 7' },
];
export const BovList = () => (
  <List sort={{ field: 'id', order: 'DESC' }}>
    <Datagrid rowClick="edit">
      <TextField source="id" />
      {/* <TextField source="name" /> */}
      <SelectField source="name" label="ra.bov.name" choices={BovChoices} />
      <TextField source="fileName" label="ra.bov.fileName" />
      <TextField source="label" label="ra.bov.displayName" />
      <BooleanField source="isEnabled" label="ra.bov.isEnabled" />
      <DateField source="createDate" label="ra.bov.createDate" />
    </Datagrid>
  </List>
);
const Editor = () => (
  <div>
    <RecordTitle title="ra.bov.title" />
    <Card>
      <SimpleForm>
        <SelectInput source="name" choices={BovChoices} label="ra.bov.name" />
        <TextInput source="fileName" label="ra.bov.fileName" fullWidth validate={required()} />
        <TextInput source="label" label="ra.bov.displayName" fullWidth validate={required()} />
        <BooleanInput source="isEnabled" label="ra.bov.isEnabled" />
      </SimpleForm>
    </Card>
  </div>
);
export const BovCreate = () => {
  return (
    <CreateBase>
      <Editor />
    </CreateBase>
  );
};

export const BovEdit = () => (
  <EditBase>
    <Editor />
  </EditBase>
);
