import React from 'react';
import { useNotify, useTranslate } from 'react-admin';
type THandleError = (error: unknown) => void;
const extractErrorMessage = (e: unknown, defaultMessage: string): string => {
  const toe = typeof e;
  if (toe === 'string') return e as string;
  if (e instanceof Error) return (e as Error).message;
  return defaultMessage;
};
export const useHandleError = (defaultMessage: string): THandleError => {
  const translate = useTranslate();
  const notify = useNotify();
  const handleError = React.useCallback((e: unknown) => {
    const message: string = extractErrorMessage(e, translate(defaultMessage));
    notify(message, { type: 'warning', messageArgs: { _: message } });
  }, []);
  return handleError;
};
