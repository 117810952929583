import { Datagrid, List, TextField, TextInput, DateField, Show, SimpleShowLayout } from 'react-admin';
import { JoinRouteField } from '../../component/JoinRouteField';
import { JSONTextField } from '../../component/JSONTextField';
import { MaskingTextField } from '../../component/MaskingTextField';
import { maskingEmail, maskingName, maskingPhone } from '../../utils/masking';

const userFilters = [<TextInput label="ra.user.search" source="q" alwaysOn />];

export const UserList = () => {
  return (
    <List sort={{ field: 'id', order: 'DESC' }} filters={userFilters}>
      <Datagrid rowClick="show" bulkActionButtons={false}>
        <MaskingTextField source="email" label="ra.user.id" masking={maskingEmail} />
        <MaskingTextField source="name" label="ra.user.name" masking={maskingName} />
        <TextField source="joinRoute" label="ra.user.joinRoute" />
        <TextField source="snsType" label="ra.user.snsType" />
        <MaskingTextField source="phone" label="ra.user.phone" masking={maskingPhone} />
        <DateField source="createDate" label="ra.user.createDate" />
      </Datagrid>
    </List>
  );
};

export const UserShow = () => (
  <Show emptyWhileLoading>
    <SimpleShowLayout>
      <TextField label="ID" source="id" />
      <MaskingTextField label="ra.user.id" source="email" masking={maskingEmail} />
      <MaskingTextField label="ra.user.name" source="name" masking={maskingName} />
      <JoinRouteField label="ra.user.joinRoute" source="joinRoute" link />
      <MaskingTextField label="ra.user.phone" source="phone" masking={maskingPhone} />
      <DateField label="ra.user.createDate" source="createDate" />
    </SimpleShowLayout>
    <br />
    <SimpleShowLayout>
      <TextField label="ra.user.deviceName" source="deviceName" />
      <DateField label="ra.user.installDate" source="deviceInstallDate" />
      <DateField label="ra.user.warrantDate" source="deviceWarrantDate" />
      <JSONTextField label="ra.user.deviceSettings" source="deviceSettings" />
    </SimpleShowLayout>
  </Show>
);
