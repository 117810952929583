import * as React from 'react';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import Title from './Title';
import { totalUsers } from '../../api/dashboard';

export default function TotalUsers() {
  const [usersCount, setUsersCount] = React.useState<number>();
  const loadData = async () => {
    const { count } = await totalUsers();
    setUsersCount(count);
  };
  React.useEffect(() => {
    loadData();
  }, []);
  return (
    <React.Fragment>
      <Title>Total Users</Title>
      <Typography component="p" variant="h4">
        {usersCount ?? '-'}
      </Typography>
      <Typography color="text.secondary" sx={{ flex: 1 }}>
        {new Date().toUTCString()}
      </Typography>
    </React.Fragment>
  );
}
