import * as React from 'react';
import { memo, FC, ElementType } from 'react';
import get from 'lodash/get';
import Typography, { TypographyProps } from '@mui/material/Typography';
import { useRecordContext } from 'ra-core';
import { InjectedFieldProps, Link, PublicFieldProps } from 'react-admin';

export interface JoinRouteFieldProps extends PublicFieldProps, InjectedFieldProps, Omit<TypographyProps, 'textAlign'> {
  link?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  component?: ElementType<any>;
}

export const JoinRouteField: FC<JoinRouteFieldProps> = memo(props => {
  const { className, source, link, ...rest } = props;
  const record = useRecordContext(props);
  const value = get(record, source ?? '');
  const dealerName = get(record, 'dealerName');
  const dealerId = get(record, 'dealerId');

  return (
    <Typography component="span" variant="body2" className={className} {...rest}>
      {value === 'new' ? (
        link ? (
          <>
            New<Link to={`../../dealers/${dealerId}/show`}>({dealerName})</Link>
          </>
        ) : (
          `New(${dealerName})`
        )
      ) : (
        value
      )}
    </Typography>
  );
});

// what? TypeScript loses the displayName if we don't set it explicitly
JoinRouteField.displayName = 'JoinRouteField';

JoinRouteField.propTypes = {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  ...Typography.propTypes,
};
