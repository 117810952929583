import { RichTextInput, RichTextInputToolbar } from 'ra-input-rich-text';
import { Card } from '@mui/material';
import {
  CreateBase,
  Datagrid,
  DateField,
  EditBase,
  FileField,
  FileInput,
  ImageField,
  ImageInput,
  Labeled,
  List,
  NumberInput,
  RichTextField,
  Show,
  SimpleForm,
  SimpleShowLayout,
  TextField,
  TextInput,
  useRecordContext,
  useTranslate,
} from 'react-admin';
import { RecordTitle } from '../../component/RecordTitle';
import { SupportedLanguages } from '../../common/i18nProvider';

export const ManualList = () => {
  const translate = useTranslate();
  return (
    <List sort={{ field: 'id', order: 'DESC' }}>
      <Datagrid rowClick="edit">
        <TextField source="id" />
        {SupportedLanguages.map(locale => (
          <TextField
            key={locale}
            source={`title[${locale}]`}
            label={`${translate('ra.manual.title')} - ${translate(`ra.languages.${locale}`)}`}
          />
        ))}
        <DateField source="createDate" label={translate('ra.manual.createDate')} />
      </Datagrid>
    </List>
  );
};

const ManualEditor = (props: any) => {
  const translate = useTranslate();
  const record = useRecordContext(props);
  console.log('ManualEdit', record);
  return (
    <div>
      <RecordTitle title={translate('ra.side.manuals')} />
      <Card>
        <SimpleForm>
          {SupportedLanguages.map(locale => (
            <TextInput
              key={locale}
              source={`title[${locale}]`}
              label={`${translate('ra.manual.title')} - ${translate(`ra.languages.${locale}`)}`}
              fullWidth
            />
          ))}

          {SupportedLanguages.map(locale => (
            <RichTextInput
              key={locale}
              source={`content[${locale}]`}
              label={`${translate(`ra.manual.content`)} - ${translate(`ra.languages.${locale}`)}`}
              toolbar={<RichTextInputToolbar size="small" />}
              fullWidth
            />
          ))}

          <NumberInput source="order" label={translate(`ra.manual.order`)} />
          <TextInput source="youtube" label="YouTube" fullWidth />

          <ImageInput
            source="icon"
            label="Icon"
            accept="image/png"
            placeholder={<p>{translate(`ra.manual.imageDragGuide`)}</p>}
          >
            <ImageField source="icon" title="Icon" className="check-board" />
          </ImageInput>
          {record?.icon && (
            <Labeled label="Current Icon">
              <ImageField source="icon" src="src" title="originalname" className="check-board" />
            </Labeled>
          )}
          <br />
          <FileInput
            source="pdf"
            label="PDF"
            accept="application/pdf"
            placeholder={<p>{translate(`ra.manual.pdfDragGuide`)}</p>}
            onChange={e => console.log('FileInput', e)}
          >
            <FileField source="pdf" title="PDF" target="new" />
          </FileInput>
          {record?.pdfFile && (
            <Labeled label="Current PDF">
              <FileField source="pdfFiles" title="originalname" src="src" target="new" />
            </Labeled>
          )}
        </SimpleForm>
      </Card>
    </div>
  );
};

export const ManualEdit = () => (
  <EditBase>
    <ManualEditor />
  </EditBase>
);

export const ManualCreate = () => (
  <CreateBase>
    <ManualEditor />
  </CreateBase>
);

export const ManualDetail = () => (
  <Show emptyWhileLoading>
    <SimpleShowLayout>
      <TextField source="email" />
      <TextField source="name" />
      <RichTextField source="content" />
      <DateField label="Publication date" source="published_at" />
    </SimpleShowLayout>
  </Show>
);
