// ra.auth.username
import _ from 'lodash';
import { defaultI18nProvider, I18nProvider } from 'react-admin';
import { ko } from './ko';
import { en } from './en';
let currentLocale = (localStorage.getItem('locale') ?? 'ko') === 'en' ? en : ko;
export type TSupportedLanguage = { ko: 'ko'; en: 'en' };
export const SupportedLanguage: TSupportedLanguage = { ko: 'ko', en: 'en' };
export const SupportedLanguages = [SupportedLanguage.ko, SupportedLanguage.en];
export const i18nProvider: I18nProvider = {
  translate: (key: string, options) => {
    const r = _.get(currentLocale, key);
    const r2 = defaultI18nProvider.translate(key, options);
    return r ?? r2;
  },
  changeLocale: (locale: string, options?: any): Promise<void> => {
    // console.log('changeLocale', locale, options);
    localStorage.setItem('locale', locale);
    currentLocale = locale === 'ko' ? ko : en;
    return Promise.resolve();
  },
  getLocale: () => {
    const locale = localStorage.getItem('locale') ?? 'ko';
    currentLocale = locale === 'ko' ? ko : en;
    return locale;
  },
  getLocales: () =>
    SupportedLanguages.map(locale => {
      return { locale, name: _.get(currentLocale, `ra.languages.${locale}`) ?? locale };
    }),
};
