import api from '.';
import { User } from '../typing/common';
import { GetOneResult } from 'react-admin';

export interface ChartData {
  date: string;
  count: string | number;
}

export const usersChart = async (start?: string, close?: string): Promise<ChartData[]> => {
  const { data } = await api.get('dashboard/users-chart', {
    params: { start, close },
  });

  console.log(data);

  return (data as ChartData[]).map(({ date, count }) => {
    return {
      date,
      count: typeof count === 'string' ? parseInt(count, 10) : count,
    } as ChartData;
  });
};

export const totalUsers = async (): Promise<{ count: number }> => {
  const result = await api.get(`dashboard/total-users`);
  return result.data as { count: number };
};
