export const en = {
  resources: {
    admins: {
      fields: {
        id: 'ID',
      },
    },
    users: { fields: { id: 'ID', title: 'Title' } },
    manuals: { fields: { id: 'ID', title: 'Title' } },
    dealers: {
      fields: {
        id: 'ID',
        name: 'Name',
        email: 'Email',
        password: 'Password',
        passwordConfirm: 'Password Confirm',
      },
    },
  },
  ra: {
    statusCodeKey: {
      // 관리자 로그인
      adminLoginSuccess: '',
      adminLoginFailed: '',
      adminLoginFailedNotFoundUser: '',
      // 딜러 로그인
      dealerLoginSuccess: '',
      dealerLoginFailed: '',
      dealerLoginFailedNotFoundUser: '',
      // 로그인
      loginSuccess: '',
      loginFailed: '',
      loginFailedNotFoundUser: '',
      // SNS 로그인
      snsLoginSuccess: '',
      snsLoginFailed: '',
      snsLoginFailedNotFoundUser: '',
      // 로그아웃
      logoutSuccess: '',
      logoutFailed: '',
      // CHECK AUTH
      checkAuthSuccess: '',
      checkAuthFailed: '',
      checkAuthFailedNotFoundUser: '',
      // Token
      tokenRefreshSuccess: '',
      tokenRefreshFailed: '',
      // 사용자 목록 조회
      getUserListSuccess: '',
      getUserListFailed: '',
      // 사용자 상세 조회
      getUserSuccess: '',
      getUserFailed: '',
      // 사용자 삭제
      deleteUserSuccess: '',
      deleteUserFailed: '',
      // 이메일 인증 코드 전송
      sendEmailAuthSuccess: '',
      sendEmailAuthFailed: '',
      // 이메일 인증 코드 체크
      checkEmailAuthSuccess: '',
      checkEmailAuthFailed: '',
      // 딜러 코드 확인
      checkDealerCodeSuccess: '',
      checkDealerCodeFailed: '',
      // 임시 비밀번호 전송( 비밀번호 변경 )
      sendTempPasswordSuccess: '',
      sendTempPasswordFailed: '',
      // 회원가입( 사용자 등록 )
      signupSuccess: '',
      signupFailed: '',
      signupFailedDuplicateEmail: '',
      signupFailedDuplicateNickname: '',
      // SNS 회원가입( 사용자 등록 )
      snsSignupSuccess: '',
      snsSignupFailed: '',
      snsSignupFailedDuplicateUid: '',
      snsSignupFailedDuplicateEmail: '',
      // 회원정보 수정
      modifyUserInfoSuccess: '',
      modifyUserInfoFailed: '',
      // 회원 설정 정보 수정
      modifyUserSettingInfoSuccess: '',
      modifyUserSettingInfoFailed: '',
      // 기기 등록
      registerDeviceSuccess: '',
      registerDeviceFailed: '',
      // 회원탈퇴
      withdrawalSuccess: '',
      withdrawalFailed: '',
      // Apple 로그인 사용자 이름 저장
      saveAppleUserNameSuccess: '',
      saveAppleUserNameFailed: '',
      // Apple 로그인 사용자 이름 조회
      getAppleUserNameSuccess: '',
      getAppleUserNameFailed: '',
      // 이미지 업로드
      uploadImageSuccess: '',
      uploadImageFailed: '',
      // 파일 업로드
      uploadFileSuccess: '',
      uploadFileFailed: '',
      // 파일 조회
      getFileSuccess: '',
      getFileFailed: '',
      // 파일 삭제
      deleteFileSuccess: '',
      deleteFileFailed: '',
      // 메뉴얼 전체 목록 조회
      getManualListSuccess: '',
      getManualListFailed: '',
      // 메뉴얼 조회
      getManualSuccess: '',
      getManualFailed: '',
      // BLE 기기 등록
      registerBleDeviceSuccess: '',
      registerBleDeviceFailed: '',
      // BLE 기기 조회
      getBleDeviceSuccess: '',
      getBleDeviceFailed: '',
      // 관리자 목록 조회
      getAdminListSuccess: '',
      getAdminListFailed: '',
      // 관리자 등록
      signupAdminSuccess: '',
      signupAdminFailed: '',
      // 관리자 조회
      getAdminSuccess: '',
      getAdminFailed: '',
      // 관리자 수정
      modifyAdminSuccess: '',
      modifyAdminFailed: '',
      // 관리자 삭제
      deleteAdminSuccess: '',
      deleteAdminFailed: '',
      // 가입 인증 메일 전송
      sendAuthEmailSuccess: '',
      sendAuthEmailFailed: '',
      // 딜러 가입
      signupDealerSuccess: '',
      signupDealerFailed: '',
      signupDealerFailedDuplicateEmail: '',
      signupDealerFailedDuplicateNickname: '',
      // 딜러 목록 조회
      getDealerListSuccess: '',
      getDealerListFailed: '',
      // 딜러 상세 조회
      getDealerSuccess: '',
      getDealerFailed: '',
      // 딜러 수정
      modifyDealerSuccess: '',
      modifyDealerFailed: '',
      // 딜러 삭제
      deleteDealerSuccess: '',
      deleteDealerFailed: '',
      // 딜러 가입 승인
      approveDealerSuccess: '',
      approveDealerFailed: '',
      // 딜러 패스워드 초기화
      resetDealerPasswordSuccess: '',
      resetDealerPasswordFailed: '',
      // 메뉴얼 등록
      registerManualSuccess: '',
      registerManualFailed: '',
      // 메뉴얼 수정
      modifyManualSuccess: '',
      modifyManualFailed: '',
      // 메뉴얼 삭제
      deleteManualSuccess: '',
      deleteManualFailed: '',
      // Bov 목록 조회
      getBovListSuccess: '',
      getBovListFailed: '',
      // Bov 등록
      registerBovSuccess: '',
      registerBovFailed: '',
      // Bov 상세 조회
      getBovSuccess: '',
      getBovFailed: '',
      // Bov 수정
      modifyBovSuccess: '',
      modifyBovFailed: '',
      // Bov 삭제
      deleteBovSuccess: '',
      deleteBovFailed: '',
      // 푸시 메시지 전송
      sendPushMessageSuccess: '',
      sendPushMessageFailed: '',
      // 푸시 상세 메시지 전송
      sendPushDetailMessageSuccess: '',
      sendPushDetailMessageFailed: '',
      // 푸시 상세 메시지 전송( 다건 )
      sendPushDetailMessagesSuccess: '',
      sendPushDetailMessagesFailed: '',
      // 기간별 가입자 현황 조회
      getDashboardSignupStatisticsSuccess: '',
      getDashboardSignupStatisticsFailed: '',
      // 전체 가입수 조회
      getDashboardSignupTotalSuccess: '',
      getDashboardSignupTotalFailed: '',
    },
    common: {
      // common
      add: 'Add',
      modify: 'Modify',
      delete: 'Delete',
      cancel: 'Cancel',
      confirm: 'Confirm',
      search: 'Search',
    },
    auth: {
      username: 'Admin Email',
      password: 'Password',
      signIn: 'Sign In',
      dealerLogin: 'Sign In Dealer',
      adminLogin: 'Sign In Admin',
      logout: 'Logout',
      inputEmail: 'Please Input Email',
      inputPassword: 'Please Input Password',
      inputPasswordConfirm: 'Please Input Password Confirm',
      signInError: 'Login Failed.',
      notGranted: 'Waiting for grant.',
      notFound: 'Not Found matched id.',
      notMatchPassword: 'Incorrect password.',
      notMatchPasswordConfirm: 'Not match password with password confirm.',
      cantDeleteSelf: 'Cannot delete yourself. ',
      dealer: {
        username: 'Dealer Email',
        password: 'Password',
        join: 'Sign Up for Dealer',
        joinSuccess: 'You have been registered as a member. We will try to approve it as soon as possible!',
        joinError: 'Member registration failed. ',
        findPassword: 'Find Password',
        sendEmailKey: 'Send email verify key',
        emailVerifyKey: 'Email Verify Key',
        passwordConfirm: 'Password Confirm',
        resetPassword: 'Reset Password',
        newPassword: 'New Password',
        newPasswordConfirm: 'New Password Confirm',
        needToResetPasswordNotify: 'Signd in tempoary password. Should change password.',
        needToResetPasswordHelp1: 'Signd in tempoary password.',
        needToResetPasswordHelp2: 'Should change password.',

        name: 'Company Name',
        dealerName: 'Dealer Name',
        phone: 'Phone',
        country: 'Country',
        postcode: 'Post Code',
        address: 'Address',
        addressDetail: 'Address Detail',
        businessLicenseNumber: 'Business License Number',

        joinCancel: 'Cancel',
        finishFindPW: 'Email verify key sent your email.',

        // join
        checkEmail: 'Please check your email address.',
        failSendEmail: 'Email sending failed.',
        checkEmailVerifyKey: 'Please check the verify key sent to your email.',
        failExistEmail: 'This email has already been registered.',
        inputPasswordMoreEight: 'Please enter a password of at least 8 characters.',
        notMatchPasswordConfirm: 'Password confirm does not match.',
        inputEmail: 'Please enter your e-mail.',
        inputEmailVerifyKey: 'Please enter your email verify key.',
        inputPassword: 'Please enter your password.',
        inputPasswordConfirm: 'Please enter password confirmation.',
        inputName: 'please Input your name.',
        inputDealerName: 'Please enter the dealer name.',
        inputPhone: 'Please enter your phone number.',
        inputCountry: 'Please select a country.',
        inputZipNumber: 'Please enter your zip code.',
        inputAddress: 'Please enter your address.',
        inputAddressDetail: 'Please enter your detailed address.',
        inputCompanyRegistrationNumber: 'Please enter your Business License Number.',
      },
    },
    user: {
      search: 'Search',
      id: 'Id',
      name: 'Name',
      email: 'Email',
      snsType: 'SNS Type',
      phone: 'Phone',
      createDate: 'CreateDate',
      joinRoute: 'Join Route',
      deviceName: 'BLOWSOME Device Name',
      installDate: 'Install Date',
      warrantDate: 'Warrent Date',
      deviceSettings: 'Device Settings',
    },
    admin: {
      name: 'Name',
      email: 'Email',
      createDate: 'Create Date',
      adminAccount: 'Admin Account',
      password: 'Password',
      passwordConfirm: 'Password Confirm',
      newPassword: 'New Password',
      newPasswordConfirm: 'New Password Confirm',
    },
    bov: {
      createDate: 'Create Date',
      title: 'BOV Sound',
      name: 'Name',
      fileName: 'File Name(Module)',
      displayName: 'Display Name',
      isEnabled: 'Enabled',
    },
    dealers: {
      search: 'Search',
      approveRegist: 'Regist Approved',
      notExistPendingApproveRegistDealer: 'There are no dealers waiting for approval to join.',
      confirmApproveRegist: 'Are you sure you want to approve the regist request?',
      successApproveRegist: "The dealer's request has been approved.",
      shopName: 'Company name',
      dealerName: 'Contact Name',
      phone: 'Phone number',
      createDate: 'Create Date',
      approve: 'Approval',
      approveDate: 'Approval Date',
      // create
      createDealerAccount: 'Create Dealer Account',
      email: 'Email',
      name: 'Name',
      password: 'Password',
      passwordConfirm: 'Password Confirm',
      // edit
      modifyDealerAccount: 'Edit Dealer Account',
      shopCode: 'Shop Code',
      companyName: 'Company name',
      businessLicenseNumber: 'Business License Number',
      country: 'country',
      zipCode: 'ZIP Code',
      address: 'Address',
      addressDetail: 'Address Detail',
    },
    notification: {
      title: 'Title',
      body: 'Body',
      messageReceiveAction: 'Message Receive Action',
      action: {
        none: 'Do nothing',
        goToManual: 'Go To Manual',
        goToWebSite: 'Go To Webpage',
      },
      payload: {
        manualPlaceholder: 'Select Manual (15 Latest) ',
        webpagePlaceholder: 'Enter web page url',
      },
      confirmSend: 'Send message to all users?',
    },
    manual: {
      title: 'Title',
      content: 'Content',
      order: 'Sort Order',
      imageDragGuide: 'Drag Icon Image to upload',
      pdfDragGuide: 'Drag Pdf Image to upload',
      createDate: 'Create Date',
    },
    validation: { required: 'Please input mandatory values' },
    side: {
      admins: 'Admins',
      users: 'Users',
      dealers: 'Dealers',
      dealerRequest: 'Dealer Requests',
      manuals: 'Manuals',
      bovSounds: 'Bov Sounds',
      notifications: 'Push Message',
      updateLog: 'Update Log',
      usersDealer: 'Users',
    },
    page: {
      dashboard: 'Dashboard',
    },
    action: {
      create: 'Add',
      export: 'CSV Download',
      edit: 'Edit',
      save: 'Save',
      delete: 'Delete',
      sendNotification: 'Send',
    },
    navigation: {
      pageRowsPerPage: 'Items per Page  : ',
      pageRangeInfo: 'Page of',
    },
    tiptap: {
      paragraph: 'Paragraph',
      heading1: 'H1',
      heading2: 'H2',
      heading3: 'H3',
      heading4: 'H4',
      heading5: 'H5',
      heading6: 'H6',
    },
    languages: {
      ko: 'Korean',
      en: 'English',
    },
    settingLanguage: {
      ko: '한국어',
      en: 'English',
    },
  },
};
