import {
  GetListParams,
  GetListResult,
  UpdateParams,
  DeleteManyParams,
  CreateParams,
  CreateResult,
  DeleteManyResult,
  DeleteParams,
  DeleteResult,
  GetOneResult,
  UpdateResult,
} from 'ra-core';
import api from '.';
import { getCurrentAuth } from '../providers/authProvider';
import { Admin, CreateAdminDto, UpdateAdminDto } from '../typing/common';
import { ListResponse } from '../typing/common';

export const admins = async ({
  pagination: { page, perPage },
  sort: { field, order },
  filter: { q },
}: GetListParams): Promise<GetListResult<Admin>> => {
  const {
    data: { items, meta },
  }: ListResponse<Admin> = await api.get('admins', {
    params: {
      page,
      size: perPage,
      sort: field,
      order,
      search: q,
    },
  });

  return {
    data: items,
    total: meta.totalItems,
    pageInfo: {
      hasNextPage: meta.totalPages > meta.currentPage,
      hasPreviousPage: meta.currentPage > 1,
    },
  };
};

export const admin = async (id: number): Promise<GetOneResult<Admin>> => {
  const result = await api.get(`admins/${id}`);
  return result as GetOneResult<Admin>;
};

export const createAdmin = async ({
  data: { name, email, new_password, new_passwordConfirm },
}: CreateParams<CreateAdminDto>): Promise<CreateResult<Admin>> => {
  if (new_password !== new_passwordConfirm) throw new Error('ra.auth.notMatchPasswordConfirm');
  const result = await api.post('admins', {
    name,
    email,
    password: new_password,
  });
  return result as CreateResult<Admin>;
};

export const updateAdmin = async ({
  data: { id, new_password, new_passwordConfirm, name },
}: UpdateParams<UpdateAdminDto>): Promise<UpdateResult<Admin>> => {
  if (new_password !== new_passwordConfirm) throw new Error('ra.auth.notMatchPasswordConfirm');

  const result = await api.patch(`admins/${id}`, {
    name,
    password: new_password,
  });
  return result as UpdateResult<Admin>;
};

export const deleteAdmin = async ({ id }: DeleteParams<Admin>): Promise<DeleteResult> => {
  const auth = getCurrentAuth();
  if (!auth) return Promise.reject('need to login ');
  const { payload: self } = auth;
  console.log(self.id, id);

  if (self.id === id) return Promise.reject('ra.auth.cantDeleteSelf');
  const result = await api.delete(`admins/${id}`);
  return { data: true } as DeleteResult;
};

export const deleteAdmins = async ({ ids }: DeleteManyParams<Admin>): Promise<DeleteManyResult> => {
  const auth = getCurrentAuth();
  if (!auth) return Promise.reject('need to login ');
  const { payload: self } = auth;
  console.log(self.id, ids);

  if (ids.includes(self.id)) return Promise.reject('ra.auth.cant');
  const result = await api.delete(`admins/${ids.join(',')}`);
  return {} as DeleteManyResult;
};
