// in src/Layout.js
import { AppBar, Layout as RALayout, LayoutProps } from 'react-admin';
import { Menu } from './menu';

export const Layout = (props: LayoutProps) => (
  <RALayout
    {...props}
    appBar={AppBar}
    menu={Menu}
    // notification={MyNotification}
  />
);
