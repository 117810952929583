import { TSupportedLanguage } from './../common/i18nProvider';
export interface ListResponse<T> {
  data: {
    items: T[];
    meta: {
      totalItems: number;
      itemCount: number;
      itemsPerPage: number;
      totalPages: number;
      currentPage: number;
    };
  };
}

export type LoginMode = 'admin' | 'dealer' | 'dealer_join' | 'dealer_find_pw' | 'dealer_reset_pw';
export interface User {
  id: number;
  name: string;
  email: string;
  phone: string;
  firstLat: number;
  firstLng: number;
  lat: number;
  lng: number;
  joinRoute: string;
  dealerKey: string;
  createDate: string;
}

export interface IFile {
  id: number;
  path: string;
  filename: string;
  title: string;
  originalname: string;
  type: string;
  size: number;
  width: number | null;
  height: number | null;
  userId: number | null;
  adminId: number;
  createDate: string;
  updateDate: string;
  withdrawDate: string;
}

// admin
export interface Admin {
  id: number;
  name: string;
  email: string;
  createDate: string;
}

export interface CreateAdminDto extends Omit<Admin, 'id' | 'createDate'> {
  new_password: string;
  new_passwordConfirm: string;
}

export interface UpdateAdminDto extends Omit<Admin, 'createDate'> {
  new_password: string;
  new_passwordConfirm: string;
}

// dealer
export interface Dealer {
  id: number;
  dealerKey: string;
  email: string;

  name: string;
  dealerName: string;

  phone: string;

  country: string;
  postcode: string;
  address: string;
  addressDetail: string;
  businessLicenseNumber: string;

  isGranted: boolean;

  createDate: string;
  updateDate: string;
  withdrawDate: string;
  grantedDate: string;
}

export interface CreateDealerDto
  extends Omit<
    Dealer,
    'id' | 'dealerKey' | 'isGranted' | 'createDate' | 'updateDate' | 'withdrawDate' | 'grantedDate'
  > {
  emailVerifyKey: string;
  password: string;
  passwordConfirm: string;
}
export type CreateDealerDtoKey = keyof CreateDealerDto;

export const CreateDealerDtoDefaultValue: CreateDealerDto = {
  email: '',
  emailVerifyKey: '',
  password: '',
  passwordConfirm: '',

  name: '',
  dealerName: '',
  phone: '',
  country: '',
  postcode: '',
  address: '',
  addressDetail: '',
  businessLicenseNumber: '',
};

export interface UpdateDealerDto
  extends Omit<Dealer, 'isGranted' | 'createDate' | 'updateDate' | 'withdrawDate' | 'grantedDate'> {
  password: string;
  passwordConfirm: string;
}
export interface Manual {
  id: number;
  title: { [key in keyof TSupportedLanguage]: string };
  order: number;
  icon?: string; // path
  iconFile?: IFile; // real
  iconFiles?: IFile[]; // for form component
  pdf?: string; // path
  pdfFile?: IFile; // real
  pdfFiles?: IFile[]; // for form component
  content: { [key in keyof TSupportedLanguage]: string };
  youtube?: string;
  createDate: string;
  updateDate: string;
}
export interface CreateManualDto extends Omit<Manual, 'id' | 'createDate' | 'updateDate' | 'icon' | 'pdf'> {
  icon: { rawFile: string };
  pdf: { rawFile: string };
}
export interface UpdateManualDto extends Omit<Manual, 'createDate' | 'updateDate' | 'icon' | 'pdf'> {
  icon: { rawFile: string };
  pdf: { rawFile: string };
}
export interface Bov {
  id: number;
  name: string;
  fileName: string;
  isEnabled: boolean;
  createDate: Date;
  updateDate?: Date;
}

export type CreateBovDto = Omit<Bov, 'id' | 'createDate' | 'updateDate'>;
export type UpdateBovDto = Omit<Bov, 'createDate' | 'updateDate'>;

export interface PushNotificationDto {
  title: { [key in keyof TSupportedLanguage]: string };
  body: { [key in keyof TSupportedLanguage]: string };
  payload: 'none' | { manual: number } | { webpage: string };
}
