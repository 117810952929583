import { AdminAuthResult } from './auth';
import { GetListParams, GetListResult } from 'ra-core';
import api from '.';
import { CreateDealerDto, Dealer, UpdateDealerDto } from '../typing/common';
import { ListResponse } from '../typing/common';
import {
  DeleteManyParams,
  DeleteManyResult,
  DeleteParams,
  DeleteResult,
  GetOneResult,
  UpdateParams,
  UpdateResult,
} from 'react-admin';

export const dealers = async (params: GetListParams, isGranted = false): Promise<GetListResult<Dealer>> => {
  const {
    pagination: { page, perPage },
    sort: { field, order },
    filter: { q },
  } = params;
  const {
    data: { items, meta },
  }: ListResponse<Dealer> = await api.get('dealers', {
    params: {
      page,
      size: perPage,
      sort: field,
      order,
      search: q,
      isGranted,
    },
  });

  return {
    data: items,
    total: meta.totalItems,
    pageInfo: {
      hasNextPage: meta.totalPages > meta.currentPage,
      hasPreviousPage: meta.currentPage > 1,
    },
  };
};

export const dealer = async (id: number): Promise<GetOneResult<Dealer>> => {
  const result = await api.get(`dealers/${id}`);
  return result as GetOneResult<Dealer>;
};

interface ResetPassword {
  id: number;
  accessToken: string;
  password: string;
}

export const resetPasswordDealer = async ({ id, accessToken, password }: ResetPassword): Promise<boolean> => {
  const result = await api.patch(
    `dealers/${id}/resetPassword`,
    { password },
    { headers: { Authorization: `Bearer ${accessToken}` } }
  );
  return result.data as boolean;
};

export const updateDealers = async ({
  data: { id, password, passwordConfirm, name },
}: UpdateParams<UpdateDealerDto>): Promise<UpdateResult<Dealer>> => {
  if (password !== passwordConfirm) throw new Error('ra.auth.notMatchPasswordConfirm');

  const result = await api.patch(`dealers/${id}`, {
    name,
    password,
  });
  return result as UpdateResult<Dealer>;
};

export const deleteDealer = async ({ id }: DeleteParams<Dealer>): Promise<DeleteResult> => {
  // const { payload: self } = await getCurrentAuth();
  // console.log(self.id, id);

  const result = await api.delete(`dealers/${id}`);
  return { data: true } as DeleteResult;
};

export const deleteDealers = async ({ ids }: DeleteManyParams<Dealer>): Promise<DeleteManyResult> => {
  // const { payload: self } = await getCurrentAuth();
  // console.log(self.id, ids);

  const result = await api.delete(`dealers/${ids.join(',')}`);
  return {} as DeleteManyResult;
};

export const sendEmailKey = async (email: string): Promise<boolean> => {
  const language = localStorage.getItem('locale') ?? 'ko';
  api.defaults.headers.common.Language = language;
  const result = await api.get('dealers/email-verify', { params: { email } });
  return result.data as boolean;
};

export const joinDealer = async (dto: CreateDealerDto): Promise<boolean> => {
  const language = localStorage.getItem('locale') ?? 'ko';
  api.defaults.headers.common.Language = language;
  const result = await api.post('dealers/join', dto);
  return result.data;
};

export const findPasswordDealer = async (email: string): Promise<boolean> => {
  const result = await api.get('dealers/password-reset', { params: { email } });
  return result.data as boolean;
};

export const grantDealer = async (id: number) => {
  const result = await api.patch(`dealers/${id}/grant`);
  return result;
};
